/* global MatgenGlobal */

class SidebarDisplay {
  static getAction(action, node, options = false) {
    const config = SidebarDisplay.getActionConfig(action, node, options);
    if (options) {
      return SidebarDisplay.actionBadge({ action, config });
    } else if (config.type === 'dropdown') {
      return SidebarDisplay.actionDropDown(config);
    } else {
      return SidebarDisplay.actionIcon(config);
    }
  }

  static actionIcon({
    actionClass,
    id,
    componentId,
    title,
    iconClasses,
    iconHTML,
    additionalClasses = false,
    sort_order = 0,
  } = {}) {
    return {
      markup: `
        <a
        href="#"
        class="layer-action ${actionClass}${
        additionalClasses ? ` ${additionalClasses}` : ''
      }"
        data-id="${id}"
        ${componentId ? `data-component-id="${componentId}"` : ''}
        data-bs-toggle="tooltip"
        data-placement="auto"
        title="${title}">
        ${iconHTML ? iconHTML : `<i class="${iconClasses}"></i>`}
        </a>
      `,
      sort_order,
    };
  }

  static actionDropDown({
    actionClass,
    id,
    componentId,
    title,
    iconHTML,
    actions = [],
    sort_order,
  } = {}) {
    return {
      markup: `
        <div class="layer-action dropdown" data-bs-toggle="tooltip" data-placement="auto" title="${title}">
          <a class="${actionClass}" data-component-id="${componentId}" href="#" id="more-${id}" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ${iconHTML}
          </a>

          <div class="dropdown-menu" aria-labelledby="more-${id}">
            ${actions
              .map(
                a =>
                  `<a class="dropdown-item ${a.action}" data-id="${id}" data-component-id="${componentId}" href="#">${a.label}</a>`
              )
              .join('')}
          </div>
        </div>
      `,
      sort_order,
    };
  }

  static actionBadge({ action, config }) {
    return {
      markup: `
          <a class="layer-action badge-btn badge-action ${action}" data-id="${
        config.id
      }" href="#" data-toggle="tooltip" data-placement="auto" title="${
        MatgenGlobal.AuthUser.getUserRole() === 'user'
          ? 'Select option'
          : 'Manage options'
      }">
            <i class="fas fa-sitemap"></i> <span class="badge bg-secondary">${
              config.options.length
            }</span>
          </a>
      `,
      sort_order: config.sort_order,
    };
  }

  static checkCompliance(node) {
    if (
      !node.readOrder &&
      !Number.isInteger(node.readOrder) &&
      !node.componentReadOrder &&
      !Number.isInteger(node.componentReadOrder)
    ) {
      return false;
    }
    let group;
    const tag = node.pdfTag ? node.pdfTag : node.componentPdfTag;
    switch (node.type) {
      default:
        MatgenGlobal.UI.handleError(
          `Unhandled node type: ${node.type}`,
          'The node type does not have handling coded.'
        );
        return false;
      case 'group-component':
        group = node.objects.map(o => SidebarDisplay.checkCompliance(o));
        if (group.every(o => o === true)) {
          return true;
        }
        return false;
      case 'image':
      case 'rect':
      case 'circle':
      case 'ellipse':
      case 'triangle':
      case 'polygon':
      case 'line':
      case 'polyline':
        if (node.richText) {
          if (!tag || !['P'].includes(tag)) {
            return false;
          }
        } else {
          if (!tag || !['FIGURE', 'ARTIFACT'].includes(tag)) {
            return false;
          }
          if (tag === 'FIGURE' && (!node.altText || node.altText === '')) {
            return false;
          }
        }
        return true;
      case 'textbox':
        if (
          !tag ||
          !['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'A'].includes(tag)
        ) {
          return false;
        }
        if (
          tag === 'A' &&
          !node.userEditable &&
          (!node.link || node.link === '')
        ) {
          return false;
        }
        return true;
    }
  }

  static isConnection(node) {
    return node.studyDataConnection;
  }

  static getActionConfig(actionClass, node, options) {
    const compliant = SidebarDisplay.checkCompliance(node);
    const isConnection = SidebarDisplay.isConnection(node);
    const actions = [
      {
        actionClass: 'component-edit-group',
        id: node.id,
        componentId: node.componentId,
        title: 'Edit Component',
        iconClasses: 'fas fa-edit',
        sort_order: 100,
      },
      {
        actionClass: 'edit-text',
        id: node.id,
        componentId: node.componentId,
        title: 'Edit text',
        iconClasses: 'fas fa-edit',
        sort_order: 101,
      },
      {
        actionClass: 'edit-rich-text',
        id: node.id,
        componentId: node.componentId,
        title: 'Edit RichText Component',
        iconClasses: 'fas fa-code',
        sort_order: 102,
      },
      {
        actionClass: 'mark-rich-text-uneditable',
        id: node.id,
        componentId: node.componentId,
        title: 'Mark RichText Uneditable',
        iconClasses: 'fas fa-ban',
        additionalClasses: node.uneditable ? ' text-danger' : '',
        sort_order: 101,
      },
      {
        actionClass: 'create-rich-text',
        id: node.id,
        componentId: node.componentId,
        title: 'Create RichText Component',
        iconClasses: 'fas fa-code',
        sort_order: 200,
      },
      {
        actionClass: 'component-edit',
        id: node.id,
        componentId: node.componentId,
        title: node.userEditable ? 'Disable User Edit' : 'Enable User Edit',
        iconClasses: 'fas fa-user-edit',
        sort_order: 1100,
      },
      {
        actionClass: 'component-uploads',
        id: node.id,
        componentId: node.componentId,
        title: node.allowUploads ? 'Disable uploads' : 'Enable Uploads',
        iconClasses: 'fas fa-file-upload',
        sort_order: 1200,
      },
      {
        type: 'dropdown',
        actionClass: 'component-save',
        id: node.id,
        componentId: node.componentId,
        title: 'Save component/option',
        iconHTML:
          '<i class="fas fa-save"></i><i class="fa fa-exclamation component-dirty"></i>',
        actions: [
          {
            label: 'Save existing option',
            action: 'save-option',
          },
          {
            label: 'Save as new option',
            action: 'create-option',
          },
        ],
        sort_order: 1400,
      },
      {
        actionClass: 'make-uploader',
        id: node.id,
        title: 'Make uploader',
        iconClasses: 'fas fa-upload',
        sort_order: 1450,
      },
      {
        actionClass: 'manage-options',
        id: node.componentId,
        title:
          MatgenGlobal.AuthUser.getUserRole() === 'user'
            ? 'Select option'
            : 'Manage options',
        iconClasses: 'fas fa-sitemap',
        options,
        sort_order: 1500,
      },
      {
        actionClass: 'template-make-component',
        id: node.id,
        title: 'Make component',
        iconClasses: 'fas fa-sitemap',
        sort_order: 1550,
      },
      {
        actionClass: 'add-option',
        id: node.id,
        title: 'Add option',
        iconHTML: '<i class="fas fa-image"></i><i class="fal fa-plus"></i>',
        sort_order: 1900,
      },
      {
        actionClass: 'component-add-text',
        id: node.id,
        componentId: node.componentId,
        title: 'Add text to component',
        iconHTML: '<i class="fas fa-text"></i><i class="fal fa-plus"></i>',
        sort_order: 2200,
      },
      {
        actionClass: 'component-add-image',
        id: node.id,
        componentId: node.componentId,
        title: 'Add image to component',
        iconHTML: '<i class="fas fa-image"></i><i class="fal fa-plus"></i>',
        sort_order: 2300,
      },
      {
        actionClass: 'object-accessible',
        id: node.id,
        componentId: node.componentId,
        title: '508 Compliance',
        iconClasses: 'fab fa-accessible-icon',
        additionalClasses: compliant ? '' : ' text-danger',
        sort_order: 2301,
      },
      {
        actionClass: 'object-accessible-component',
        id: node.id,
        title: '508 Compliance',
        iconClasses: 'fab fa-accessible-icon',
        additionalClasses: compliant ? '' : ' text-danger',
        sort_order: 2302,
      },
      {
        actionClass: 'object-accessible-group-component',
        id: node.id,
        title: '508 Compliance',
        iconClasses: 'fab fa-accessible-icon',
        additionalClasses: compliant ? '' : ' text-danger',
        sort_order: 2303,
      },
      {
        actionClass: 'object-study-data',
        id: node.id,
        title: 'Study Data Connection',
        iconClasses: 'fas fa-file-import',
        additionalClasses: isConnection ? ' text-danger' : '',
        sort_order: 2350,
      },
      {
        actionClass: 'object-visible',
        id: node.id,
        componentId: node.componentId,
        title: 'Toggle visibility',
        iconClasses: node.visible ? 'fas fa-eye-slash' : 'fas fa-eye',
        sort_order: 2400,
      },
      {
        actionClass: 'object-locked',
        id: node.id,
        componentId: node.componentId,
        title: 'Toggle lock',
        iconClasses: node.selectable ? 'fas fa-lock' : 'fas fa-lock-open',
        sort_order: 2500,
      },
      {
        actionClass: 'delete-object',
        id: node.id,
        componentId: node.componentId,
        title: `Delete ${node.type}`,
        iconClasses: 'fas fa-trash',
        sort_order: 2600,
      },
      {
        actionClass: 'delete-component',
        id: node.id,
        componentId: node.componentId,
        title: 'Delete component',
        iconClasses: 'fas fa-trash',
        sort_order: 2700,
      },
    ];
    return actions.find(a => a.actionClass === actionClass);
  }
}

export { SidebarDisplay };
