/* global MatgenGlobal $ */

import MatgenUIFunctions from '../matgen-ui/ui-functions.js';
import { Uploader } from '../matgen-ui/components/Uploader.js';

const init = () => {
  if (MatgenGlobal) {
    $(document).off('matgen-table-action');
    $(document).on('matgen-table-action', async e => {
      console.log('matgen-table-action:', e.detail);
      MatgenGlobal.hideTooltips();
      let template, data, uploader;
      switch (e.detail.action) {
        default:
          break;
        case 'material-download':
          MatgenGlobal.MatgenUIFunctions.handleMaterialDownload(e.detail.id);
          break;
        case 'material-view':
          MatgenGlobal.MatgenUIFunctions.viewMaterial(e.detail.id);
          break;
        case 'material-edit':
          MatgenGlobal.Router.goTo(`/materials/${e.detail.id}`);
          break;
        case 'material-edit-data':
          MatgenGlobal.MatgenUIFunctions.editMaterialData(
            e.detail.id,
            e.detail.row
          );
          break;
        case 'material-delete':
          MatgenGlobal.UI.confirm(
            'Delete Material?',
            '<p style="max-width:320px;">Are you sure you want to delete this material?</p>',
            'Continue',
            'Cancel',
            () => {
              MatgenGlobal.MatgenUIFunctions.deleteMaterial(
                e.detail.id,
                e.detail.row
              );
            }
          );
          break;
        case 'template-download':
          template = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading template',
            promise: MatgenGlobal.Data.getTemplate(e.detail.id),
          });
          MatgenGlobal.CurrentTemplate = template;
          MatgenGlobal.hideSidebar = true;
          if (template.type === 'PDF' && !MatgenGlobal.Suppress508) {
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Downloading template',
              promise: MatgenGlobal.MatgenUIFunctions.downloadTemplate508(
                e.detail.id,
                true,
                template
              ),
            });
          } else {
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Downloading template',
              promise: MatgenGlobal.MatgenUIFunctions.downloadTemplate(
                e.detail.id
              ),
            });
          }
          delete MatgenGlobal.hideSidebar;
          break;
        case 'template-view':
          MatgenGlobal.MatgenUIFunctions.viewTemplate(e.detail.id);
          break;
        case 'template-images':
          MatgenGlobal.MatgenUIFunctions.downloadImages(e.detail.id);
          break;
        case 'new-template':
          MatgenGlobal.MatgenUIFunctions.editTemplateData();
          break;
        case 'import-template':
          uploader = new Uploader();
          uploader.fileSelect(
            'import-uploader',
            async () => {
              const file = document.getElementById('import-uploader').files[0];
              if (file) {
                MatgenGlobal.MatgenUIFunctions.importTemplate(file);
              }
            },
            'body',
            '.zip'
          );
          break;
        case 'template-edit':
          MatgenGlobal.MatgenUIFunctions.editTemplate(e.detail.id);
          break;
        case 'template-delete':
          MatgenGlobal.MatgenUIFunctions.deleteTemplate(e.detail.id);
          break;
        case 'template-edit-data':
          MatgenUIFunctions.editTemplateData(e.detail.id);
          break;
        case 'template-edit-name':
          MatgenGlobal.MatgenUIFunctions.editTemplateName(
            e.detail.id,
            e.detail.row
          );
          break;
        case 'template-tags':
          MatgenGlobal.Router.goTo(`/tags/${e.detail.id}`);
          break;
        case 'template-toggle-publish':
          MatgenGlobal.MatgenUIFunctions.togglePublished(e.detail.id);
          break;
        case 'template-export':
          MatgenUIFunctions.exportTemplate(e.detail.id);
          break;
        case 'template-duplicate':
          MatgenGlobal.UI.promptModal({
            title: 'Copy Template',
            label: 'New Name:',
            action: name =>
              MatgenUIFunctions.duplicateTemplate(e.detail.id, name),
          });
          break;
        case 'template-mark-tested':
          data = $(MatgenGlobal.Tables.TemplateTable.tableSelector)
            .DataTable()
            .row($(e.detail.row))
            .data();
          if (parseInt(data.tested) === 1) {
            console.log('Mark untested');
          } else {
            console.log('Mark tested');
          }
          break;
        case 'microsite-toggle-publish':
          MatgenGlobal.MatgenUIFunctions.togglePublished(
            e.detail.id,
            'microsite',
            e.detail.row
          );
          break;
        case 'microsite-delete':
          data = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Deleting website',
            promise: MatgenGlobal.MatgenUIFunctions.deleteMicrosite(
              e.detail.id,
              e.detail.row
            ),
          });
          break;
        case 'microsite-edit':
          window.location.href = `microsite-generator.html?study_id=${e.detail.alt_id}`;
          break;
        case 'user-edit':
          data = $(e.detail.row)
            .parent()
            .parent()
            .DataTable()
            .row($(e.detail.row))
            .data();
          MatgenGlobal.MatgenUIFunctions.userModal(data);
          break;
        case 'user-delete':
          try {
            const token = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading session token',
              promise: MatgenGlobal.AuthUser.getSessionToken(),
            });
            const opts = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Deleting user',
              promise: MatgenGlobal.Amplify.API.del(
                'authenticated',
                `/users/${e.detail.id}`,
                opts
              ),
            });
            MatgenGlobal.UI.alertModal(
              'User Deleted',
              `The user has been deleted.`
            );
            $(e.detail.row)
              .parent()
              .parent()
              .DataTable()
              .row($(e.detail.row))
              .remove()
              .draw();
          } catch (e) {
            console.error(e);
            MatgenGlobal.UI.alertModal(
              'Error Deleting User',
              `There was an error deleting the user.`
            );
          }
          break;
        case 'new-tenant':
          MatgenUIFunctions.saveForm('tenant', 'Tenant');
          break;
        case 'tenant-edit':
          MatgenUIFunctions.saveForm('tenant', 'Tenant', e.detail.id);
          break;
        case 'new-questionnaire':
          MatgenUIFunctions.createQuestionnaire();
          break;
        case 'new-section':
          MatgenUIFunctions.saveForm('section', 'Section');
          break;
        case 'new-question':
          MatgenUIFunctions.saveForm('question', 'Question');
          break;
        case 'new-answer':
          MatgenUIFunctions.saveForm('answer', 'Answer');
          break;
        case 'questionnaire-edit':
          MatgenGlobal.Router.goTo(`edit-questionnaire/${e.detail.id}`);
          break;
        case 'questionnaire-delete':
          MatgenGlobal.UI.confirm(
            'Confirm Delete',
            'Are you sure you wish to delete this questionnaire?',
            'Continue',
            'Cancel',
            () => MatgenUIFunctions.deleteQuestionnaire(e.detail.id)
          );
          break;
        case 'sections-edit':
          MatgenUIFunctions.saveForm('section', 'Section', e.detail.id);
          break;
        case 'Questions-edit':
          MatgenUIFunctions.saveForm('question', 'Question', e.detail.id);
          break;
        case 'Answers-edit':
          MatgenUIFunctions.saveForm('answer', 'Answer', e.detail.id);
          break;

        case 'tenant-view-questionnaires':
          MatgenUIFunctions.viewQuestionnaires(e.detail.id);
          break;
        case 'questionnaire-view-sections':
          MatgenUIFunctions.viewSections(e.detail.id);
          break;
        case 'sections-view-questions':
          MatgenUIFunctions.viewQuestions(e.detail.id);
          break;
        case 'Questions-view-answers':
          MatgenUIFunctions.viewAnswers(e.detail.id);
          break;

        case 'new-study':
          window.location.href = `/microsite-generator.html`;
          break;
        case 'study-edit':
          window.location.href = `/microsite-generator.html?study_id=${e.detail.id}`;
          break;
        case 'study-materials':
          window.location.href = `/generator.html?study_id=${e.detail.id}#/step3`;
          break;
        case 'study-delete':
          $(`#studymodal`).modal('hide');
          try {
            const token = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading session token',
              promise: MatgenGlobal.AuthUser.getSessionToken(),
            });
            const opts = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            };
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Deleting study data',
              promise: MatgenGlobal.Amplify.API.del(
                'authenticated',
                `/studies/${e.detail.id}`,
                opts
              ),
            });
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Deleting study files',
              promise: MatgenGlobal.Amplify.Storage.remove(
                `${e.detail.id}.json`,
                {
                  level: 'protected',
                  contentType: 'application/json',
                  progressCallback(e) {
                    MatgenGlobal.UI.loaderProgress(e);
                  },
                }
              ),
            });

            MatgenGlobal.UI.alertModal(
              'Study Deleted',
              '<p>The study has been deleted.<p>'
            );

            $(e.detail.row)
              .parent()
              .parent()
              .DataTable()
              .row($(e.detail.row))
              .remove()
              .draw();
          } catch (e) {
            console.error(e);
            MatgenGlobal.UI.alertModal(
              'Error Deleting Study',
              '<p>There was an error deleting the study.<p>'
            );
          }
          break;
      }
    });
  }
};

export { init };
