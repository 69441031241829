/* global $, MatgenGlobal */

import { M4CTable } from '../M4CTable.js';

const TemplateActions = item => {
  let actions = [
    {
      action: 'download',
      title: 'Download Template',
      icon: 'far fa-file-download',
      color: 'primary',
    },
    {
      action: 'view',
      title: 'Preview Template',
      icon: 'far fa-image',
      color: 'primary',
    },
    {
      action: 'edit-data',
      title: 'Edit Template Data',
      icon: 'fas fa-edit',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'edit-name',
      title: 'Edit Template Name',
      icon: 'fas fa-edit',
      color: 'primary',
      role: 'admin',
    },
    {
      action: 'edit',
      title: 'Edit Template',
      icon: 'fas fa-paint-brush',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'toggle-publish',
      title: item.published === 1 ? 'Unpublish' : 'Publish',
      icon: item.published === 1 ? 'fas fa-eye-slash' : 'fas fa-eye',
      color: item.published === 1 ? 'primary' : 'warning',
    },
    {
      action: 'tags',
      title: 'Manage Tags',
      icon: 'fas fa-tags',
      color: item.tag_count > 0 ? 'primary' : 'warning',
    },
    {
      action: 'duplicate',
      title: 'Duplicate Template',
      icon: 'fas fa-copy',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'delete',
      title: 'Delete Template',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];
  if (MatgenGlobal.CustomTemplateActions) {
    const custom = MatgenGlobal.CustomTemplateActions(item);
    actions = custom.concat(actions);
  }
  return actions;
};
const TemplateColumns = [
  { data: 'name', title: 'Name' },
  {
    data: 'type',
    title: 'Type',
    render: item => item.toUpperCase(),
  },
  {
    data: 'created',
    title: 'Created',
    render: item => new Date(item).toLocaleString('en-US'),
  },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item =>
      M4CTable.actionsHTML(item, 'template', TemplateActions(item)),
  },
];

const TemplateSummaryTable = new M4CTable({
  columns: TemplateColumns,
  table: 'templates',
  title: 'Templates',
  callbacks: {
    createdRow: (row, data) => {
      if (data.published !== 1) {
        $(row).addClass('unpublished');
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .removeClass('btn-primary')
          .addClass('btn-warning');
      } else {
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .addClass('btn-primary')
          .removeClass('btn-warning');
      }
      if (data.tag_count === 0) {
        $(row).addClass('untagged');
      }
      if (data.type === 'FILE') {
        $(row).addClass('file-type');
      }
    },
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
    initComplete: s => {
      s.oInstance
        .api()
        .order([4, 'desc'])
        .draw();
    },
  },
});

export { TemplateSummaryTable };
