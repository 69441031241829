/* global MatgenGlobal */

import { M4CTable } from '../M4CTable.js';

const TenantActions = () => {
  const actions = [
    {
      action: 'edit',
      title: 'Edit Tenant',
      icon: 'fas fa-edit',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'view-questionnaires',
      title: 'View Questionnaires',
      icon: 'fa-solid fa-comments-question-check',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'delete',
      title: 'Delete Tenant',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];

  return actions;
};

const TenantColumns = [
  { data: 'id', title: 'ID' },
  { data: 'name', title: 'Name' },
  { data: 'is508', title: 'Is 508' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item => M4CTable.actionsHTML(item, 'tenant', TenantActions(item)),
  },
];

const TenantTable = new M4CTable({
  columns: TenantColumns,
  table: 'tenant',
  title: 'Tenants',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
});

export { TenantTable };
