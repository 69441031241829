/* global MatgenGlobal */

import hotkeys from 'hotkeys-js';
import { UI } from '../../ui.js';

const keys = [
  'esc',
  '↩︎',
  'ctrl+c',
  'command+c',
  'ctrl+shift+b',
  'command+shift+b',
  'ctrl+shift+f',
  'command+shift+f',
  'ctrl+shift+l',
  'command+shift+l',
  'ctrl+shift+r',
  'command+shift+r',
  'ctrl+shift+c',
  'command+shift+c',
  'ctrl+v',
  'command+v',
  'ctrl+x',
  'command+x',
  'ctrl+s',
  'command+s',
  'ctrl+g',
  'command+g',
  'ctrl+shift+g',
  'command+shift+g',
  'ctrl+i',
  'command+i',
  'ctrl+o',
  'command+o',
  'ctrl+shift+0',
  'command+shift+0',
  'ctrl+r',
  'command+r',
  'ctrl+z',
  'command+z',
  'ctrl+y',
  'command+y',
  'up',
  'down',
  'left',
  'right',
  'del',
  'delete',
  'backspace',
];

function handleAlignment(activeObject, type) {
  const groupWidth = activeObject.width;
  //const groupHeight = activeObject.getHeight();

  const objs = activeObject.getObjects();

  for (let i = 0; i < objs.length; i++) {
    const itemWidth = objs[i].getBoundingRect().width;
    //const itemHeight = objs[i].getBoundingRect().height;

    switch (type) {
      default:
        break;
      case 'left':
        objs[i].set({
          left: -(groupWidth / 2),
          originX: 'left',
        });
        break;
      case 'center':
        objs[i].set({
          left: 0 - itemWidth / 2,
          originX: 'left',
        });
        break;
      case 'right':
        objs[i].set({
          left: groupWidth / 2 - itemWidth / 2,
          originX: 'center',
        });
        break;
    }
    objs[i].setCoords();
    MatgenGlobal.editor.cur().fabric.renderAll();
  }
  MatgenGlobal.editor
    .cur()
    .fabric.discardActiveObject()
    .renderAll();
}

const init = () => {
  hotkeys(keys.join(','), (event, handler) => {
    event.preventDefault();
    event.stopPropagation();

    let canvas, activeObject;
    if (MatgenGlobal.editor) {
      canvas = MatgenGlobal.editor.cur();
      activeObject = canvas.fabric.getActiveObject();
    }

    switch (handler.key) {
      case 'esc':
        console.log('you pressed esc!');
        break;
      case '↩︎':
        console.log('you pressed enter!');
        break;
      case 'ctrl+c':
      case 'command+c':
        if (activeObject) {
          canvas.copy();
        } else {
          console.error('No item selected');
        }
        break;
      case 'ctrl+shift+b':
      case 'command+shift+b':
        if (activeObject) {
          canvas.fabric.sendBackwards(activeObject);
          canvas.fabric.requestRenderAll();
          UI.canvasChanged();
        } else {
          console.error('No item selected');
        }
        break;
      case 'ctrl+shift+f':
      case 'command+shift+f':
        if (activeObject) {
          canvas.fabric.bringForward(activeObject);
          canvas.fabric.requestRenderAll();
          UI.canvasChanged();
        } else {
          console.error('No item selected');
        }
        break;
      case 'ctrl+shift+l':
      case 'command+shift+l':
        if (activeObject) {
          handleAlignment(activeObject, 'left');
        }
        break;
      case 'ctrl+shift+c':
      case 'command+shift+c':
        if (activeObject) {
          handleAlignment(activeObject, 'center');
        }
        break;
      case 'ctrl+shift+r':
      case 'command+shift+r':
        if (activeObject) {
          handleAlignment(activeObject, 'right');
        }
        break;
      case 'ctrl+v':
      case 'command+v':
        if (activeObject) {
          canvas.paste();
          canvas.fabric.renderAll();
        } else {
          console.error('No item selected');
        }
        break;
      case 'ctrl+s':
      case 'command+s':
        console.log('you pressed ctrl+s!');
        break;
      case 'ctrl+g':
      case 'command+g':
        UI.group(activeObject);
        break;
      case 'ctrl+shift+g':
      case 'command+shift+g':
        if (!activeObject) {
          console.error('No item selected');
          return;
        }
        if (activeObject.type == 'group') {
          MatgenGlobal.editor.groupComponentId = activeObject.componentId;
          MatgenGlobal.editor.groupId = activeObject.id;

          const items = activeObject._objects;
          activeObject._restoreObjectsState();
          canvas.fabric.remove(activeObject);
          for (let i = 0; i < items.length; i++) {
            delete items[i].groupId;
            if (activeObject.currentOptionId) {
              items[i].currentOptionId = activeObject.currentOptionId;
            }
            canvas.fabric.add(items[i]);
            canvas.fabric.item(canvas.fabric.size() - 1).hasControls = true;
          }

          canvas.fabric.requestRenderAll();
          UI.canvasChanged(activeObject.componentId);
        }

        break;
      case 'ctrl+i':
      case 'command+i':
        MatgenGlobal.editor.cur().zoom();
        break;
      case 'ctrl+o':
      case 'command+o':
        MatgenGlobal.editor.cur().zoom(true);
        break;
      case 'ctrl+shift+0':
      case 'command+shift+0':
        MatgenGlobal.editor.resetZoom();
        break;
      case 'up':
        activeObject.top -= MatgenGlobal.NUDGE_STEP
          ? MatgenGlobal.NUDGE_STEP
          : 1;
        activeObject.setCoords();
        canvas.fabric.requestRenderAll();
        break;
      case 'down':
        activeObject.top += MatgenGlobal.NUDGE_STEP
          ? MatgenGlobal.NUDGE_STEP
          : 1;
        activeObject.setCoords();
        canvas.fabric.requestRenderAll();
        break;
      case 'left':
        activeObject.left -= MatgenGlobal.NUDGE_STEP
          ? MatgenGlobal.NUDGE_STEP
          : 1;
        activeObject.setCoords();
        canvas.fabric.requestRenderAll();
        break;
      case 'right':
        activeObject.left += MatgenGlobal.NUDGE_STEP
          ? MatgenGlobal.NUDGE_STEP
          : 1;
        activeObject.setCoords();
        canvas.fabric.requestRenderAll();
        break;
      default:
        console.log(event);
    }
  });
};

export { init };
