export const TextForm = () => {
  return `
    <div id="form-container" class="container-fluid">
      <form
        id="editor-text-form"
        class="matgen-static-form"
        autocomplete="true"
        style="min-width:450px;"
      >

        <div class="row">
          <div class="col">
            <div class="form-error"></div>
            <div class="form-success"></div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col d-flex justify-content-center" id="editor-text-flags">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inputRichText">
              <label class="form-check-label" for="inputRichText">Rich Text</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inputMaterialDate">
              <label class="form-check-label" for="inputMaterialDate">Material Date</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inputUserEditable">
              <label class="form-check-label" for="inputUserEditable">User Editable</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inputUseThemeColorText">
              <label class="form-check-label" for="inputUseThemeColorText">Use Theme Color</label>
              <div id="themeColorTextOpacityContainer" class="align-items-center">
                <label for="themeColorTextOpacity" class="form-label visually-hidden">Theme Color Opacity</label>
                <input type="range" min="0" max="255" value="255" class="form-range" id="themeColorTextOpacity">
                <input type="text" class="form-control" id="themeColorTextOpacityNumber" data-lpignore="true" />
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mt-3" id="fontOptions">
          <div class="col">
            <label for="font-picker" class="form-label visually-hidden">Font</label>
            <input type="text" class="form-control" id="font-picker"  data-lpignore="true">
          </div>
          <div class="col">
            <label for="inputFontSize" class="form-label visually-hidden">Font Size (px)</label>
            <input type="text" class="form-control" id="inputFontSize" placeholder="Font Size (px)" data-lpignore="true">
          </div>
          <div class="col">
            <label for="inputLineHeight" class="form-label visually-hidden">Line Height</label>
            <input type="text" class="form-control" id="inputLineHeight" placeholder="Line Height">
          </div>
          <div class="col">
            <div class="color-picker-form form-control d-flex justify-content-between align-items-center">
              Font Color:
              <span class="color-picker"></span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label for="inputText" class="form-label">Text</label>
            <textarea
              class="form-control"
              id="inputText"
              style="width:100%;min-height:5em;"
            ></textarea>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col" id="textAlignContainer">
            <label for="textAlign">Text Alignment</label>
            <select name="textAlign" id="textAlign" class="form-select" aria-label="Text alignment">
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
              <option value="justify">Justify</option>
              <option value="justify-left">Justify Left</option>
              <option value="justify-center">Justify Center</option>
              <option value="justify-right">Justify Right</option>
            </select>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col" id="rich-text-instructions">
            <p><b>Creating Rich Text Components</b></p>
            <ol>
              <li>Select your font and alignment options.</li>
              <li>Click the "Apply" button<br>(This will place a temporary item on the canvas with the text "RT.")</li>
              <li>Adjust the text field with the text "RT" inside of it to set the required size and position.</li>
              <li>Click the Create Rich Text Component icon <i class="fa-solid fa-code"></i> in the layer's icons in the sidebar.</li>
            </ol>
          </div>
        </div>

        <input type="hidden" id="text-edit-font-type" value="" />
        <input type="hidden" id="text-edit-id" value="" />

      </form>
    </div>
  `;
};
