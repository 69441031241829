/* global MatgenGlobal */

export default class MatgenAPI {
  cancel() {
    if (
      MatgenGlobal.CurrentAPIRequests &&
      MatgenGlobal.CurrentAPIRequests.length > 0
    ) {
      for (let i = 0; i < MatgenGlobal.CurrentAPIRequests.length; i++) {
        MatgenGlobal.Amplify.API.cancel(
          MatgenGlobal.CurrentAPIRequests[i].length,
          'Cancel request'
        );
      }
      MatgenGlobal.CurrentAPIRequests = [];
    }
    if (
      MatgenGlobal.CurrentS3Requests &&
      MatgenGlobal.CurrentS3Requests.length > 0
    ) {
      for (let i = 0; i < MatgenGlobal.CurrentS3Requests.length; i++) {
        MatgenGlobal.Amplify.Storage.cancel(
          MatgenGlobal.CurrentS3Requests[i].length,
          'Cancel request'
        );
      }
      MatgenGlobal.CurrentS3Requests = [];
    }
  }

  endpoint(resource, method) {
    let endpoint = 'authenticated';
    if (
      MatgenGlobal &&
      MatgenGlobal.AuthUser &&
      MatgenGlobal.AuthUser.token !== false
    ) {
      endpoint = 'devauth';
    }

    const url = new URL(`https://fakeurl.api${resource}`);
    const queryParams = Object.fromEntries(url.searchParams);

    let path = url.pathname.replace(/^\/|\/$/g, '');

    const segments = url.pathname.replace(/^\/|\/$/g, '').split('/');
    let idSegment = false;
    if (segments.length === 2) {
      if (
        segments[1].match(
          /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        )
      ) {
        idSegment = true;
        path = segments[0];
      }
    }

    method = method.toUpperCase();
    switch (method) {
      default:
        break;
      case 'GET':
        if (
          url.pathname === '/tenants' ||
          url.pathname === '/questions' ||
          url.pathname === '/study_template' ||
          (path === 'template-check' && (queryParams.id || idSegment)) ||
          (url.pathname === '/templates' && queryParams.tenant_id) ||
          ((path === 'pages' || path === 'tags' || path === 'tenants') &&
            (queryParams.template_id || idSegment)) ||
          (path === 'matgen-questionnaire' &&
            (queryParams.tenant_id || idSegment)) ||
          (idSegment && (path === 'templates' || path === 'components')) ||
          (path === 'options' && (queryParams.component_id || idSegment))
        ) {
          endpoint = 'public';
        }
        break;
      case 'POST':
        if (path === 'mailer') {
          endpoint = 'mailer';
        }
        if (path === 'templates') {
          endpoint = 'public';
        }
        break;
    }
    return endpoint;
  }

  async request(resource, method = 'GET', data = false) {
    let opts = {};
    if (
      MatgenGlobal &&
      MatgenGlobal.AuthUser &&
      MatgenGlobal.AuthUser.token === false
    ) {
      try {
        const token = await MatgenGlobal.AuthUser.getSessionToken();
        opts = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      } catch (e) {
        console.error(e);
      }
    }

    const endpoint = this.endpoint(resource, method, data);

    try {
      let response = false;
      switch (method) {
        default:
          break;
        case 'GET':
          response = MatgenGlobal.Amplify.API.get(endpoint, resource, opts);
          break;
        case 'POST':
          opts.body = data;
          response = MatgenGlobal.Amplify.API.post(endpoint, resource, opts);
          break;
        case 'PATCH':
          opts.body = data;
          response = MatgenGlobal.Amplify.API.patch(endpoint, resource, opts);
          break;
        case 'DELETE':
          response = MatgenGlobal.Amplify.API.del(endpoint, resource, opts);
          break;
      }
      MatgenGlobal.CurrentAPIRequests.push({ response, endpoint, resource });
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getS3File({
    path,
    type = 'blob',
    noCache = false,
    level = 'public',
    customPrefix = false,
  } = {}) {
    const params = {
      level,
      ...(customPrefix && { customPrefix: { public: '' } }),
      download: true,
      cacheControl: noCache ? 'no-cache' : 'must-revalidate',
      progressCallback(e) {
        MatgenGlobal.UI.loaderProgress(e);
      },
    };

    try {
      const res = await MatgenGlobal.Amplify.Storage.get(path, params);
      if (type === 'json') {
        const json = await new Response(res.Body).json();
        return json;
      }
      const blob = await new Response(res.Body).blob();
      return blob;
    } catch (e) {
      console.error(e, path, params);
      return false;
    }
  }
}
