/* global M4CGlobal, MatgenGlobal */

import { v4 as UUID } from 'uuid';
import { emit } from '../matgen-ui/common/helpers.js';

const initError = e => `Loader module: ${e} not initialized.`;
const argError = (f, e) => `Loader module ${f}(): ${e} attribute missing.`;
const MESSAGE_DECAY_DELAY = 50;
const LOADER_STOP_DELAY = 50;

class M4CLoaderManagerLoader {
  constructor({ loader, id, debug = false, manager } = {}) {
    if (!loader) {
      throw new Error(initError('loader'));
    }
    if (!id) {
      throw new Error(initError('loader id'));
    }
    const initTime = Date.now();
    this.loader = loader;
    this.promises = [];
    this.startArray = [];
    this.id = id;
    this.initTime = initTime;
    this.completed = 0;
    this.ticks = 0;
    this.debug = debug;
    this.manager = manager;
  }

  start({ message, promise, suppressError = false, group } = {}) {
    if (!message) {
      throw new Error(argError('start function', 'message'));
    }
    if (!promise) {
      throw new Error(argError('start function', 'promise'));
    }
    if (!this.instanceId) {
      this.instanceId = UUID();
    }
    if (typeof promise === 'function') {
      promise = promise();
    }
    const p = {
      promise,
      loaderInstanceId: this.instanceId,
      fulfilled: false,
      error: false,
      startTime: Date.now(),
      message,
      group,
      suppressError,
    };
    this.startArray.push({
      message,
      group,
    });
    promise
      .then(data => {
        p.fulfilled = true;
        p.endTime = Date.now();
        p.data = data;
      })
      .catch(e => {
        p.error = true;
        p.errorMessage = e.message;
      });
    this.promises.push(p);

    if (MatgenGlobal.promiseLoader) {
      emit({
        event: 'matgen-event-loader-start',
        detail: { message, p, suppressError },
      });
    } else {
      this.loader.start({
        message: `${this.message().join('\n')}`,
      });
    }

    if (this.ticks === 0) {
      this.tick();
    }
    return promise;
  }

  message() {
    let messages = this.pending().map(p => `${p.message}`);
    if (M4CGlobal.LOADER_MESSAGE_MAX === 0) {
      messages = [];
    } else if (M4CGlobal.LOADER_MESSAGE_MAX && messages.slice) {
      messages = messages.slice(0, M4CGlobal.LOADER_MESSAGE_MAX);
    }
    if (M4CGlobal.SHOW_DUPE_COUNT) {
      messages = messages.reduce(
        (result, value) => ({ ...result, [value]: (result[value] || 0) + 1 }),
        {}
      );
      return Object.keys(messages).map(o => {
        return `${o}${messages[o] > 1 ? ` (${messages[o]})` : ''}`;
      });
    } else {
      messages = [...new Set(messages)];
      return messages;
    }
  }

  pending() {
    return this.promises.filter(p => {
      if (p.fulfilled === false) {
        return true;
      }
      if (p.fulfilled && Date.now() - p.endTime < MESSAGE_DECAY_DELAY) {
        return true;
      }
      return false;
    });
  }

  errored() {
    return this.promises.filter(p => {
      if (p.error === true) {
        return true;
      }
      return false;
    });
  }

  reportErrored() {
    return this.promises.filter(p => {
      if (p.error === true && !p.suppressError) {
        return true;
      }
      return false;
    });
  }

  tick() {
    this.ticks++;
    if (this.ticks > 250) {
      return false;
    }
    if (this.pending().length > 0 && this.errored().length === 0) {
      this.tickTimer = window.setTimeout(async () => {
        if (MatgenGlobal.promiseLoader) {
          emit({
            event: 'matgen-event-loader-tick',
            detail: { message: `${this.message().join('\n')}` },
          });
        } else {
          this.loader.start({
            message: `${this.message().join('\n')}`,
          });
        }
        this.tick();
      }, 250);
    } else if (this.reportErrored().length > 0) {
      console.error(
        'Loading error:',
        this.promises.filter(p => {
          return p.error === true;
        })
      );
      if (
        !MatgenGlobal.dontHandleLoadingErrors &&
        !MatgenGlobal.promiseLoader
      ) {
        MatgenGlobal.UI.handleError(
          'Loading Error',
          this.errored()
            .map(p =>
              p.errorMessage && p.errorMessage !== ''
                ? p.errorMessage
                : 'An error has occurred. Please try again later or contact support.'
            )
            .join('<br>')
        );
      } else {
        if (MatgenGlobal.promiseLoader) {
          emit({
            event: 'matgen-event-loader-error',
            detail: { errors: this.errored() },
          });
        }
        console.error('Loader API error:', this.errored());
      }
      this.stop();
      this.error = true;
    } else {
      this.stop();
    }
  }
  stop() {
    if (this.debug) {
      return false;
    }
    window.clearTimeout(this.tickTimer);
    delete this.instanceId;
    window.setTimeout(
      () => {
        if (MatgenGlobal.promiseLoader) {
          emit({
            event: 'matgen-event-loader-stop',
            detail: {
              startArray: this.startArray,
              promises: this.promises,
            },
          });
        } else {
          this.loader.stop();
        }
        this.promises = [];
        this.ticks = 0;
      },
      M4CGlobal.LOADER_STOP_DELAY
        ? M4CGlobal.LOADER_STOP_DELAY
        : LOADER_STOP_DELAY
    );
  }
}

export default M4CLoaderManagerLoader;
