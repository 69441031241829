/* global MatgenGlobal */

import { v4 as UUID } from 'uuid';
import MatgenAPI from './matgen-api.js';

class MatgenData {
  constructor() {
    this.API = new MatgenAPI();
  }

  getTemplateFileURL(id, tenant_id, ext) {
    return `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${tenant_id}/templates/${id}${ext}`;
  }

  async getOptionPreviewURL(id) {
    const option = await this.API.request(`/options/${id}`);
    const component = await this.API.request(
      `/components/${option.component_id}`
    );
    let template = await this.API.request(
      `/templates/${component.template_id}`
    );
    if (Array.isArray(template)) {
      template = template[0];
    }
    return `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/options/${id}.png`;
  }

  async getMaterialPreviewURL(id, pid) {
    const credentials = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    return `https://${
      MatgenGlobal.AmplifyConfig.Storage.bucket
    }.s3.amazonaws.com/protected/${
      credentials.identityId
    }/${id}/${pid}.png?cache_buster=${new Date().getTime()}`;
  }

  async sendEmail(data) {
    const response = await this.API.request(`/mailer`, 'POST', data);
    return response;
  }

  getTable(table, arg = null) {
    if (arg) table += arg;
    return this.API.request(`/${table}`);
  }

  async getUsers(s) {
    const response = await this.API.request(`/users?s=${s}`);
    return response;
  }

  async getTenantId(name) {
    const response = await this.API.request(`/tenants?name=${name}`);
    return response ? response[0] : false;
  }

  async getTenants() {
    const response = await this.API.request(`/tenants`);
    return response;
  }

  async getTenant(id) {
    const response = await this.API.request(`/tenants/${id}`);
    return response;
  }

  async getQuestionnaires(tenant_id) {
    const response = await this.API.request(
      `/questionnaires?tenant_id=${tenant_id}`
    );
    return response;
  }

  async getQuestionnaire(id) {
    const response = await this.API.request(`/matgen-questionnaire/${id}`);
    return response;
  }

  async getStudyDataQuestions() {
    const response = await this.API.request(`/study_data_questions/`);
    return response;
  }

  async getQuestionnaireRecord(id) {
    const response = await this.API.request(`/questionnaire?id=${id}`);
    return response;
  }

  async getQuestionnaireSections(id) {
    const response = await this.API.request(`/sections?questionnaire_id=${id}`);
    return response;
  }

  async getSectionQuestions(id) {
    const response = await this.API.request(`/question?section_id=${id}`);
    return response;
  }

  async getSection(id) {
    const response = await this.API.request(`/sections/${id}`);
    return response;
  }

  async getQuestion(id) {
    const response = await this.API.request(`/questions/${id}`);
    return response;
  }

  async getAnswer(id) {
    const response = await this.API.request(`/answers/${id}`);
    return response;
  }

  async getQuestionAnswers(id) {
    const response = await this.API.request(`/answers?question_id=${id}`);
    return response;
  }

  async getQuestionExamples(id) {
    const response = await this.API.request(
      `/question_examples?question_id=${id}`
    );
    return response;
  }

  async getTemplate(id) {
    const response = await this.API.request(
      `/templates/${id}?tenant_id=${MatgenGlobal.tenant_id}`
    );
    if (Array.isArray(response) && response[0]) {
      return response[0];
    } else return false;
  }

  async getComponents(id) {
    const response = await this.API.request(`/components?template_id=${id}`);
    return response;
  }

  async getMicrosite(id) {
    const response = await this.API.request(`/websites/${id}`);
    return response;
  }

  async getMaterial(id) {
    let response = await this.API.request(`/materials/${id}`);
    if (response && Array.isArray(response) && response.length === 1) {
      response = response[0];
    }
    return response;
  }

  async getTemplates(tenant_id) {
    if (tenant_id) {
      const response = await this.API.request(
        `/templates?tenant_id=${tenant_id}`
      );
      return response;
    } else {
      const response = await this.API.request(`/templates`);
      return response;
    }
  }

  async getTaggedTemplates(tenant_id, tags) {
    const response = await this.API.request(`/templates`, 'POST', {
      tenant_id,
      tags,
    });
    return response;
  }

  async getTemplateCheck(question_id) {
    const response = await this.API.request(
      `/template-check/${question_id}`,
      'GET'
    );
    return response;
  }

  async getTemplateTags(id) {
    const response = await this.API.request(`/tags?template_id=${id}`);
    return response;
  }

  async getMaterialPageFile(id, pageId) {
    try {
      const res = await this.API.getS3File({
        path: `${id}/${pageId}.json`,
        type: 'json',
        noCache: false,
        level: 'protected',
      });
      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getTemplateFile(id) {
    let page, template, res;
    try {
      page = await MatgenGlobal.Data.getPage(id);
    } catch (e) {
      console.error(e);
      return false;
    }
    try {
      template = await MatgenGlobal.Data.getTemplate(page.template_id);
    } catch (e) {
      console.error(e);
      return false;
    }
    try {
      res = await this.API.getS3File({
        path: `tenant/${template.tenant_id}/templates/${id}.json`,
        type: 'json',
        noCache: MatgenGlobal.AuthUser.getUserRole() === 'super',
        customPrefix: { public: '' },
      });
      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getPage(id) {
    try {
      const page = await this.API.request(`/pages/${id}`);
      return page;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getPages(id) {
    try {
      const pages = await this.API.request(`/pages?template_id=${id}`);
      return pages;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getFirstPage(id) {
    try {
      const pages = await this.API.request(`/pages?template_id=${id}`);
      return pages ? pages.filter(p => p.number === 0) : false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getComponent(id) {
    try {
      const response = await this.API.request(`/components/${id}`);
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getDefaultOption(id) {
    if (!id) {
      console.error('Undefined component ID');
      return false;
    }
    try {
      const component = await this.getComponent(id);

      if (!component) {
        console.error('Component not found for ID:', id);
        return false;
      }
      const template = await this.getTemplate(component.template_id);
      let json = false;
      let oid = component.default_option_id;
      try {
        json = await this.getComponentOption(oid, template.tenant_id);
      } catch (e) {
        console.error('Bad default option');
      }

      if (!json) {
        const opts = await this.getComponentOptions(id);
        if (opts && opts[0]) {
          oid = opts[0].id;
          json = await this.getComponentOption(oid, template.tenant_id);
          if (
            json !== false &&
            (MatgenGlobal.AuthUser.getUserRole() === 'super' ||
              MatgenGlobal.AuthUser.getUserRole() === 'admin')
          ) {
            await this.saveComponent(
              {
                id,
                default_option_id: oid,
              },
              true
            );
          }
        }
      }
      if (!json) {
        console.error(
          'Failed getting default option JSON:',
          component.default_option_id,
          json
        );
        return false;
      }
      if (Array.isArray(json)) {
        json = json.map(async o => {
          o.currentOptionId = oid;
          return JSON.stringify(o);
        });
      } else {
        json.currentOptionId = oid;
      }
      return { json, id: oid };
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getComponentOption(id, tenant_id) {
    try {
      const res = await this.API.getS3File({
        path: `tenant/${tenant_id}/options/${id}.json`,
        type: 'json',
        noCache: MatgenGlobal.AuthUser.getUserRole() === 'super',
        customPrefix: { public: '' },
      });
      return res;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getComponentOptions(id) {
    try {
      const json = await this.API.request(`/options?component_id=${id}`);
      let results = json;
      if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
        results = json.filter(o => o.enabled);
      }
      return results;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteTenant(id) {
    try {
      const response = await this.API.request(`/tenant/${id}`, 'DELETE');
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteExample(id) {
    try {
      const response = await this.API.request(
        `/question_example/${id}`,
        'DELETE'
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteAnswer(id) {
    try {
      const response = await this.API.request(`/answer/${id}`, 'DELETE');
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteQuestion(id) {
    try {
      const response = await this.API.request(`/question/${id}`, 'DELETE');
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteSection(id) {
    try {
      const response = await this.API.request(`/section/${id}`, 'DELETE');
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteQuestionnaire(id) {
    try {
      const response = await this.API.request(`/questionnaire/${id}`, 'DELETE');
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteComponent(id) {
    try {
      const response = await this.API.request(`/component/${id}`, 'DELETE');
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteTemplateFiles(tenant_id, page_id) {
    try {
      const response = await MatgenGlobal.Amplify.Storage.remove(
        `tenant/${tenant_id}/templates/${page_id}.png`,
        {
          level: 'public',
          customPrefix: { public: '' },
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      const response2 = await MatgenGlobal.Amplify.Storage.remove(
        `tenant/${tenant_id}/templates/${page_id}.json`,
        {
          level: 'public',
          customPrefix: { public: '' },
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return { response, response2 };
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deletePage(page_id) {
    try {
      const api_response = await this.API.request(`/page/${page_id}`, 'DELETE');
      return api_response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteComponentOptions(tenant_id, component_id) {
    try {
      const options = await this.API.request(
        `/options?component_id=${component_id}`
      );
      await Promise.all(
        options.map(o => this.deleteOptionFile(o.id, tenant_id))
      );
      await Promise.all(
        options.map(o => this.deleteOptionPreview(o.id, tenant_id))
      );
      await Promise.all(
        options.map(o => this.API.request(`/option/${o.id}`, 'DELETE'))
      );
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async duplicateTemplate(id, name) {
    try {
      const template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template data',
        promise: this.API.request(`/templates/${id}`),
      });
      if (template === false) {
        return false;
      }

      if (template.type === 'FILE') {
        const templateFile = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading template file',
          promise: this.API.getS3File({
            path: `tenant/${template.tenant_id}/templates/${template.id}${template.file_ext}`,
            customPrefix: { public: '' },
          }),
        });

        const newTemplate = Object.assign({}, template);
        newTemplate.id = UUID();
        newTemplate.name = name;

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving template data',
          promise: this.saveTemplate(newTemplate),
        });
        if (templateFile) {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving template file',
            promise: this.saveTemplateFile(
              `${newTemplate.id}${newTemplate.file_ext}`,
              newTemplate.tenant_id,
              templateFile
            ),
          });
        }

        if (
          newTemplate.preview_type === 'IMAGE' &&
          newTemplate.preview_image_ext
        ) {
          const templatePreview = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading template preview',
            promise: this.API.getS3File({
              path: `tenant/${template.tenant_id}/templates/${template.id}${template.preview_image_ext}`,
              customPrefix: { public: '' },
            }),
          });
          if (templatePreview) {
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Saving template preview',
              promise: this.saveTemplateFile(
                `${newTemplate.id}${newTemplate.preview_image_ext}`,
                newTemplate.tenant_id,
                templatePreview
              ),
            });
          }
        }
      } else {
        const pageIdMap = {};
        const componentIdMap = {};
        const optionIdMap = {};

        const pages = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading template pages',
          promise: this.API.request(`/pages?template_id=${id}`),
        });
        if (pages === false) {
          return false;
        }

        const components = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading components',
          promise: this.API.request(`/components?template_id=${id}`),
        });

        let options = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading component options',
          promise: Promise.all(
            components.map(c => this.getComponentOptions(c.id))
          ),
        });
        options = options.flat();

        const newTemplate = Object.assign({}, template);
        newTemplate.id = UUID();
        newTemplate.name = name;

        const newPages = pages.map(p => {
          const newPage = Object.assign({}, p);
          newPage.id = UUID();
          newPage.template_id = newTemplate.id;

          pageIdMap[newPage.id] = p.id;

          return newPage;
        });

        const newOptions = [];

        const newComponents = components.map(c => {
          const newComponent = Object.assign({}, c);
          newComponent.id = UUID();
          newComponent.template_id = newTemplate.id;

          componentIdMap[newComponent.id] = c.id;

          options
            .filter(o => o.component_id === c.id)
            .map(o => {
              const newOption = Object.assign({}, o);
              newOption.id = UUID();
              newOption.component_id = newComponent.id;
              if (o.id === c.default_option_id) {
                newComponent.default_option_id = newOption.id;
              }
              optionIdMap[newOption.id] = o.id;
              newOptions.push(newOption);
            });

          return newComponent;
        });

        const pageFiles = {};
        const pagePreviews = {};

        for (let i = 0; i < pages.length; i++) {
          const p = pages[i];
          const pageJSON = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading page file',
            promise: this.API.getS3File({
              path: `tenant/${template.tenant_id}/templates/${p.id}.json`,
              type: 'json',
              customPrefix: { public: '' },
            }),
          });
          pageFiles[p.id] = pageJSON;
          const ext = template.preview_image_ext
            ? template.preview_image_ext
            : '.png';
          const pagePreview = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading page preview',
            promise: this.API.getS3File({
              path: `tenant/${template.tenant_id}/templates/${p.id}${ext}`,
              customPrefix: { public: '' },
            }),
          });
          pagePreviews[p.id] = pagePreview;
        }

        const optionFiles = {};
        const optionPreviews = {};

        for (let i = 0; i < options.length; i++) {
          const o = options[i];
          const optionJSON = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading option file',
            promise: this.API.getS3File({
              path: `tenant/${template.tenant_id}/options/${o.id}.json`,
              type: 'json',
              customPrefix: { public: '' },
            }),
          });
          optionFiles[o.id] = optionJSON;
          const optionPreview = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading option preview',
            promise: this.API.getS3File({
              path: `tenant/${template.tenant_id}/options/${o.id}.png`,
              customPrefix: { public: '' },
            }),
          });
          optionPreviews[o.id] = optionPreview;
        }

        const tempPages = {};
        Object.keys(pageFiles).forEach(k => {
          tempPages[k] = JSON.stringify(pageFiles[k]);
        });

        const tempOptions = {};
        Object.keys(optionFiles).forEach(k => {
          tempOptions[k] = JSON.stringify(optionFiles[k]);
        });

        for (let j = 0; j < Object.keys(tempPages).length; j++) {
          const k = Object.keys(tempPages)[j];
          const regex = new RegExp(template.id, 'gm');
          tempPages[k] = tempPages[k].replace(regex, newTemplate.id);
        }

        for (let j = 0; j < Object.keys(tempOptions).length; j++) {
          const k = Object.keys(tempOptions)[j];
          const regex = new RegExp(template.id, 'gm');
          tempOptions[k] = tempOptions[k].replace(regex, newTemplate.id);
        }

        for (let i = 0; i < newComponents.length; i++) {
          for (let j = 0; j < Object.keys(tempPages).length; j++) {
            const k = Object.keys(tempPages)[j];
            const regex = new RegExp(componentIdMap[newComponents[i].id], 'gm');
            tempPages[k] = tempPages[k].replace(regex, newComponents[i].id);
          }

          for (let j = 0; j < Object.keys(tempOptions).length; j++) {
            const k = Object.keys(tempOptions)[j];
            const regex = new RegExp(componentIdMap[newComponents[i].id], 'gm');
            tempOptions[k] = tempOptions[k].replace(regex, newComponents[i].id);
          }
        }

        for (let i = 0; i < newOptions.length; i++) {
          for (let j = 0; j < Object.keys(tempPages).length; j++) {
            const k = Object.keys(tempPages)[j];
            const regex = new RegExp(optionIdMap[newOptions[i].id], 'gm');
            tempPages[k] = tempPages[k].replace(regex, newOptions[i].id);
          }

          for (let j = 0; j < Object.keys(tempOptions).length; j++) {
            const k = Object.keys(tempOptions)[j];
            const regex = new RegExp(optionIdMap[newOptions[i].id], 'gm');
            tempOptions[k] = tempOptions[k].replace(regex, newOptions[i].id);
          }
        }

        let promises = [];

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving new template',
          promise: this.saveTemplate(newTemplate),
        });

        for (let i = 0; i < newPages.length; i++) {
          const p = newPages[i];
          promises.push(this.savePage(p));
        }
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page',
          promise: Promise.all(promises),
        });

        promises = [];
        for (let i = 0; i < newComponents.length; i++) {
          const c = newComponents[i];
          promises.push(this.saveComponent(c));
        }
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving component',
          promise: Promise.all(promises),
        });

        promises = [];

        for (let i = 0; i < newOptions.length; i++) {
          const o = newOptions[i];
          promises.push(this.saveOptionData(o));
        }

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving component options',
          promise: Promise.all(promises),
        });

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page files',
          promise: Promise.all(
            newPages.map(async p => {
              await this.saveTemplateFile(
                `${p.id}.json`,
                newTemplate.tenant_id,
                tempPages[pageIdMap[p.id]]
              );
              await this.saveTemplateFile(
                `${p.id}.png`,
                newTemplate.tenant_id,
                pagePreviews[pageIdMap[p.id]]
              );
            })
          ),
        });

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving option files',
          promise: Promise.all(
            newOptions.map(async o => {
              await this.saveTemplateFile(
                `${o.id}.json`,
                newTemplate.tenant_id,
                tempOptions[optionIdMap[o.id]],
                true
              );

              await this.saveTemplateFile(
                `${o.id}.png`,
                newTemplate.tenant_id,
                optionPreviews[optionIdMap[o.id]],
                true
              );
            })
          ),
        });
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteTemplatePage(id) {
    try {
      const page = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading page data',
        promise: this.getPage(id),
      });
      const template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template data',
        promise: this.getTemplate(page.template_id),
      });
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template page files',
        promise: this.deleteTemplateFiles(template.tenant_id, id),
      });
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template page',
        promise: this.deletePage(id),
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'Error deleting page',
        'There was a problem deleting the page record and/or files.'
      );
      return false;
    }
  }

  async deleteTemplate(id) {
    try {
      const template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template data',
        promise: this.API.request(`/templates/${id}`),
      });
      if (template === false) {
        return false;
      }
      const pages = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template pages',
        promise: this.API.request(`/pages?template_id=${id}`),
      });
      if (pages === false) {
        return false;
      }

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template files',
        promise: Promise.all(
          pages.map(p => this.deleteTemplateFiles(template.tenant_id, p.id))
        ),
      });

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template pages',
        promise: Promise.all(pages.map(p => this.deletePage(p.id))),
      });

      const components = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template components',
        promise: this.API.request(`/components?template_id=${id}`),
      });

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template component options',
        promise: Promise.all(
          components.map(c =>
            this.deleteComponentOptions(template.tenant_id, c.id)
          )
        ),
      });

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template components',
        promise: Promise.all(components.map(c => this.deleteComponent(c.id))),
      });

      const api_response = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting template',
        promise: this.API.request(`/templates/${id}`, 'DELETE'),
      });
      return api_response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteMaterial(id) {
    try {
      const materialData = await this.API.request(`/materials/${id}`);
      if (materialData === false) {
        return false;
      }
      const pages = await this.API.request(
        `/pages?template_id=${materialData.template_id}`
      );
      if (pages === false) {
        return false;
      }
      const api_response = await this.API.request(`/materials/${id}`, 'DELETE');
      if (api_response === false) {
        return false;
      }
      return api_response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteOption(id, component_id, force = false) {
    let component, options;
    try {
      component = await this.getComponent(component_id);
      if (component === false) {
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem locating the component for this option.'
        );
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }

    if (component.default_option_id === id) {
      try {
        options = await this.getComponentOptions(component_id);
      } catch (e) {
        console.error(e);
        return false;
      }
      options = options.filter(o => o.id !== id);
      if (options === false) {
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem locating the other options to set new default.'
        );
        return false;
      }
      if (options.length === 0 && !force) {
        MatgenGlobal.UI.handleError(
          'Application Error',
          'You cannot delete this option, as it is the only option.'
        );
        return false;
      }
      try {
        if (!force) {
          await this.saveComponent(
            {
              id: component_id,
              default_option_id: options[0].id,
            },
            true
          );
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    const response = await this.API.request(`/options/${id}`, 'DELETE');
    return response;
  }

  async deleteOptionFile(id, tenant_id = false) {
    if (!tenant_id) {
      console.error('Missing tenant id');
      return false;
    }
    try {
      const response = await MatgenGlobal.Amplify.Storage.remove(
        `tenant/${tenant_id}/options/${id}.json`,
        {
          level: 'public',
          customPrefix: { public: '' },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteOptionPreview(id, tenant_id = false) {
    if (!tenant_id) {
      console.error('Missing tenant id');
      return false;
    }
    try {
      const response = await MatgenGlobal.Amplify.Storage.remove(
        `tenant/${tenant_id}/options/${id}.png`,
        {
          level: 'public',
          customPrefix: { public: '' },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveTags({ tags, id } = {}) {
    try {
      const response = await this.API.request(`/tags/`, 'POST', { tags, id });
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveMaterial(material, update = false) {
    try {
      const response = await this.API.request(
        `/materials/`,
        update ? 'PATCH' : 'POST',
        material
      );

      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveComponent(component, update = false) {
    try {
      const response = await this.API.request(
        `/components/`,
        update ? 'PATCH' : 'POST',
        component
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveOptionData(option, update = false) {
    try {
      const response = await this.API.request(
        `/options`,
        update ? 'PATCH' : 'POST',
        option
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveOptionFile(id, data, tenant_id = false) {
    if (!tenant_id) {
      console.error('Missing tenant id');
      return false;
    }
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `tenant/${tenant_id}/options/${id}.json`,
        data,
        {
          level: 'public',
          customPrefix: { public: '' },
          contentType: 'application/json',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveOptionImage(base64Data, id, tenant_id = false) {
    if (!tenant_id) {
      console.error('Missing tenant id');
      return false;
    }
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `tenant/${tenant_id}/options/${id}.png`,
        base64Data,
        {
          level: 'public',
          customPrefix: { public: '' },
          contentEncoding: 'base64',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveTemplate(template, update = false) {
    try {
      const response = await this.API.request(
        `/templates`,
        update ? 'PATCH' : 'POST',
        template
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async savePage(page) {
    try {
      const response = await this.API.request(`/pages`, 'POST', page);
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveTemplateFile(filename, tenant_id, data, option = false) {
    const folder = option === false ? 'templates' : 'options';
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `tenant/${tenant_id}/${folder}/${filename}`,
        data,
        {
          level: 'public',
          customPrefix: { public: '' },
          contentType: data.type,
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async savePageFile(id, data, tenant_id = false) {
    if (!tenant_id) {
      console.error('Missing tenant id');
      return false;
    }
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `tenant/${tenant_id}/templates/${id}.json`,
        JSON.stringify(data),
        {
          level: 'public',
          customPrefix: { public: '' },
          contentType: 'application/json',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveMaterialPageFile(id, pageId, data) {
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `${id}/${pageId}.json`,
        JSON.stringify(data),
        {
          level: 'protected',
          contentType: 'application/json',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveTemplateImage(base64Data, tenant_id = false) {
    if (!tenant_id) {
      console.error('Missing tenant id');
      return false;
    }
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `tenant/${tenant_id}/templates/${MatgenGlobal.editor.curPageId}.png`,
        base64Data,
        {
          level: 'public',
          customPrefix: { public: '' },
          contentType: 'image/png',
          contentEncoding: 'base64',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveMaterialImage(base64Data, id, pid = MatgenGlobal.editor.curPageId) {
    try {
      const response = await MatgenGlobal.Amplify.Storage.put(
        `${id}/${pid}.png`,
        base64Data,
        {
          level: 'protected',
          contentType: 'image/png',
          contentEncoding: 'base64',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveItem(type, item, update = false) {
    try {
      const response = await this.API.request(
        `/${type}`,
        update ? 'PATCH' : 'POST',
        item
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveTenant(tenant, update = false) {
    try {
      const response = await this.API.request(
        `/tenants`,
        update ? 'PATCH' : 'POST',
        tenant
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveQuestionnaire(tenant, update = false) {
    try {
      const response = await this.API.request(
        `/questionnaires`,
        update ? 'PATCH' : 'POST',
        tenant
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveMicrosite(
    id,
    subdomain,
    user_name,
    study_id,
    template_id,
    html,
    json,
    edit = false,
    last_edit
  ) {
    try {
      if (edit === false) {
        await this.API.request(`/websites`, 'POST', {
          id,
          subdomain,
          user_name,
          study_id,
          template_id,
        });
      } else {
        const params = {
          id,
          subdomain,
          user_name,
          study_id,
          template_id,
          published: false,
          edit: true,
        };
        if (last_edit) {
          params.last_edit = last_edit;
        }
        await this.API.request(`/websites`, 'POST', params);
      }
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deployMicrosite(subdomain, html) {
    try {
      await MatgenGlobal.Amplify.Storage.put(
        `microsites/${subdomain}/index.html`,
        html,
        {
          bucket: MatgenGlobal.MICROSITE_BUCKET,
          level: 'public',
          customPrefix: { public: '' },
          contentType: 'text/html',
          cacheControl: 'no-cache',
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        null,
        'There was a problem ddeploying the website. Please try again later or contact support.'
      );
    }
  }

  async checkMicrosites(user_name) {
    try {
      const response = await this.API.request(`/microsites-check/${user_name}`);
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteMicrosite(id) {
    try {
      const microsite = await this.API.request(`/websites/${id}`);
      if (microsite === false) {
        return false;
      }
      await this.API.request(`/websites/${id}`, 'DELETE');
      await MatgenGlobal.Amplify.Storage.remove(
        `microsites/${microsite.subdomain}/index.html`,
        {
          bucket: MatgenGlobal.MICROSITE_BUCKET,
          level: 'public',
          customPrefix: { public: '' },
          progressCallback(e) {
            MatgenGlobal.UI.loaderProgress(e);
          },
        }
      );
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getStudyData(study_id = false) {
    let response;

    if (study_id) {
      response = await this.API.request(`/study_data?study_id=${study_id}`);
    } else {
      response = await this.API.request(`/study_data`);
    }
    return response;
  }

  async getTemplateStudyData(template_id = false) {
    let response;

    if (template_id) {
      response = await this.API.request(
        `/template-user-content?template_id=${template_id}`
      );
    } else {
      response = await this.API.request(`/template-user-content`);
    }
    return response;
  }

  async deleteStudyData(study_id, question_id) {
    try {
      const response = await this.API.request(
        `/study_data?study_id=${study_id}&question_id=${question_id}`,
        'DELETE'
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deletePageStudyData(template_id, page_id) {
    try {
      const response = await this.API.request(
        `/template-user-content?template_id=${template_id}&page_id=${page_id}`,
        'DELETE'
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async deleteTemplateStudyData(template_id, question_id) {
    try {
      const response = await this.API.request(
        `/template-user-content?template_id=${template_id}&question_id=${question_id}`,
        'DELETE'
      );
      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveStudyData(study_data, update = false) {
    try {
      const response = await this.API.request(
        `/study_data/`,
        update ? 'PATCH' : 'POST',
        study_data
      );

      return response;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async saveTemplateStudyData(studyData, update = false) {
    try {
      const response = await this.API.request(
        `/template-user-content/`,
        update ? 'PATCH' : 'POST',
        studyData
      );
      return response;
    } catch (e) {
      if (
        e.response.data.error &&
        e.response.data.error.code === 'ER_DUP_ENTRY'
      ) {
        return false;
      }
      console.error(e);
      return false;
    }
  }

  simulateAPIError() {
    return new Promise(() => {
      throw new Error('Simulated API error');
    });
  }
}

export { MatgenData };
