/* global MatgenGlobal, M4CGlobal, $ bootstrap */

import { SidebarHeader } from '../SidebarHeader.js';
import { debouncedrichTextEditorUpdate } from '../../RichTextEditor.js';

const init = () => {
  $(document).off('click', '#grid-submit');
  $(document).on('click', '#grid-submit', () => {
    $('#grid-form').submit();
  });

  $(document).off('click', '#theme-color-submit');
  $(document).on('click', '#theme-color-submit', () => {
    $('#theme-color-form').submit();
  });

  $(document).off('click', '#template-add-item');
  $(document).on('click', '#template-add-item', e => {
    e.preventDefault();
    const cards = [
      {
        id: 'image',
        title: 'Image',
        icon: '<i class="fa-solid fa-image fa-2x"></i>',
        text: 'Select this option to add an image element.',
      },
      {
        id: 'text',
        title: 'Text',
        icon: '<i class="fa-solid fa-text fa-2x"></i>',
        text: 'Select this option to add a text element.',
      },
      {
        id: 'shape',
        title: 'Shape',
        icon: '<i class="fa-solid fa-shapes fa-2x"></i>',
        text: 'Select this option to add a shape element.',
      },
    ];
    SidebarHeader.cardsModal('add-item', 'Add item', 'Select item type', cards);
  });

  $(document).off('click', '#image');
  $(document).on('click', '#image', e => {
    e.preventDefault();
    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('file-input').files[0]) {
        MatgenGlobal.editor
          .cur()
          .addImg(
            document.getElementById('file-input').files[0],
            MatgenGlobal.editor.cur().fabric.getActiveObject()
          );
      }
      document
        .querySelector('body')
        .removeChild(document.getElementById('file-input'));
      $('#add-item-modal').modal('hide');
    });
  });

  $(document).off('click', '#text');
  $(document).on('click', '#text', e => {
    e.preventDefault();
    MatgenGlobal.UI.textForm();
    $('#add-item-modal').modal('hide');
  });

  function vis({ el, hide = true, uncheck = false, clear = false } = {}) {
    if (hide) {
      el.parent().hide();
      if (uncheck) {
        el.attr('checked', false);
      }
      if (clear) {
        if (clear !== true) {
          el.val(clear);
        } else {
          el.val('');
        }
      }
    } else {
      el.parent().show();
    }
  }

  $(document).off('click', '#editor-text-flags .form-check-inline');
  $(document).on('click', '#editor-text-flags .form-check-inline', e => {
    if ($(e.target).hasClass('form-check-input')) {
      vis({ el: $('#inputRichText'), hide: false });
      vis({ el: $('#inputMaterialDate'), hide: false });
      vis({ el: $('#inputUserEditable'), hide: false });
      vis({ el: $('#inputUseThemeColorText'), hide: false });
      vis({ el: $('#inputText'), hide: false });
      vis({ el: $('#textAlignContainer'), hide: false });
      $('#themeColorTextOpacityContainer').hide();
      $('#fontOptions').css('display', 'flex');
      $('#rich-text-instructions').hide();
      $('#textAlign option[value="justify-left"]').show();
      $('#textAlign option[value="justify-center"]').show();
      $('#textAlign option[value="justify-right"]').show();

      if ($('#inputRichText').is(':checked')) {
        vis({ el: $('#inputMaterialDate'), uncheck: true });
        vis({ el: $('#inputUserEditable'), uncheck: true });
        vis({ el: $('#inputText'), clear: true });
        $('#rich-text-instructions').show();
        if ($('#inputUseThemeColorText').is(':checked')) {
          $('#themeColorTextOpacityContainer').css('display', 'flex');
          $('#themeColorTextOpacityNumber').val(
            $('#themeColorTextOpacity').val()
          );
        }
        $('#textAlign option[value="justify-left"]').hide();
        $('#textAlign option[value="justify-center"]').hide();
        $('#textAlign option[value="justify-right"]').hide();
      } else if ($('#inputMaterialDate').is(':checked')) {
        vis({ el: $('#inputRichText'), uncheck: true });
        vis({ el: $('#inputUserEditable'), uncheck: true });
      } else if ($('#inputUserEditable').is(':checked')) {
        vis({ el: $('#inputRichText'), uncheck: true });
        vis({ el: $('#inputMaterialDate'), uncheck: true });
      } else if ($('#inputUseThemeColorText').is(':checked')) {
        $('#themeColorTextOpacityContainer').css('display', 'flex');
        $('#themeColorTextOpacityNumber').val(
          $('#themeColorTextOpacity').val()
        );
      }
    }
  });

  $(document).off('click', '#rich-text-editor-submit');
  $(document).on('click', '#rich-text-editor-submit', e => {
    const id = $(e.target).attr('data-rte-id');
    debouncedrichTextEditorUpdate({ save: true, id });
  });

  function getOpacity(selector) {
    const color = MatgenGlobal.editor
      .cur()
      .fabric.themeColor.replace('#', '')
      .substring(0, 6);
    const opacity = parseInt($(selector).val())
      .toString(16)
      .padStart(2, '0');
    MatgenGlobal.editor.cur().fabric.themeColorOpacity = opacity;
    const hexColor = `#${color}${opacity}`;
    return hexColor;
  }

  $(document).off('input', '#themeColorTextOpacity');
  $(document).on('input', '#themeColorTextOpacity', () => {
    $('#themeColorTextOpacityNumber').val($('#themeColorTextOpacity').val());

    const hexColor = getOpacity('#themeColorTextOpacityNumber');
    MatgenGlobal.editor.cur().fabric.themeColor = hexColor.substring(0, 7);

    M4CGlobal.pickr.text2.setColor(
      `${MatgenGlobal.editor.cur().fabric.themeColor}${
        MatgenGlobal.editor.cur().fabric.themeColorOpacity
          ? MatgenGlobal.editor.cur().fabric.themeColorOpacity
          : 'FF'
      }`
    );
  });

  $(document).off('input', '#themeColorTextOpacityNumber');
  $(document).on('input', '#themeColorTextOpacityNumber', () => {
    if ($('#themeColorTextOpacityNumber').val() === '') {
      $('#themeColorTextOpacity').val(0);
      $('#themeColorTextOpacityNumber').val(0);
    } else {
      $('#themeColorTextOpacity').val($('#themeColorTextOpacityNumber').val());
    }

    const hexColor = getOpacity('#themeColorTextOpacityNumber');
    MatgenGlobal.editor.cur().fabric.themeColor = hexColor.substring(0, 7);

    M4CGlobal.pickr.text2.setColor(
      `${MatgenGlobal.editor.cur().fabric.themeColor}${
        MatgenGlobal.editor.cur().fabric.themeColorOpacity
          ? MatgenGlobal.editor.cur().fabric.themeColorOpacity
          : 'FF'
      }`
    );
  });

  $(document).off('input', '#themeColorShapeOpacity');
  $(document).on('input', '#themeColorShapeOpacity', () => {
    $('#themeColorShapeOpacityNumber').val($('#themeColorShapeOpacity').val());

    const hexColor = getOpacity('#themeColorShapeOpacityNumber');
    MatgenGlobal.editor.cur().fabric.themeColor = hexColor.substring(0, 7);

    M4CGlobal.pickr.shape.setColor(
      `${MatgenGlobal.editor.cur().fabric.themeColor}${
        MatgenGlobal.editor.cur().fabric.themeColorOpacity
          ? MatgenGlobal.editor.cur().fabric.themeColorOpacity
          : 'FF'
      }`
    );
  });

  $(document).off('input', '#themeColorShapeOpacityNumber');
  $(document).on('input', '#themeColorShapeOpacityNumber', () => {
    if ($('#themeColorShapeOpacityNumber').val() === '') {
      $('#themeColorShapeOpacity').val(0);
      $('#themeColorShapeOpacityNumber').val(0);
    } else {
      $('#themeColorShapeOpacity').val(
        $('#themeColorShapeOpacityNumber').val()
      );
    }

    const hexColor = getOpacity('#themeColorShapeOpacityNumber');
    MatgenGlobal.editor.cur().fabric.themeColor = hexColor.substring(0, 7);

    M4CGlobal.pickr.shape.setColor(
      `${MatgenGlobal.editor.cur().fabric.themeColor}${
        MatgenGlobal.editor.cur().fabric.themeColorOpacity
          ? MatgenGlobal.editor.cur().fabric.themeColorOpacity
          : 'FF'
      }`
    );
  });

  $(document).off('click', '#shape');
  $(document).on('click', '#shape', e => {
    e.preventDefault();
    SidebarHeader.shapesModal();
  });

  $(document).off('click', '#circle');
  $(document).on('click', '#circle', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addCircle({
      radius: 150,
      fill: M4CGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: M4CGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
      themeColorOpacity: MatgenGlobal.editor.cur().fabric.themeColorOpacity,
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#rectangle');
  $(document).on('click', '#rectangle', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addRectangle({
      width: 150,
      height: 150,
      fill: M4CGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: M4CGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
      themeColorOpacity: MatgenGlobal.editor.cur().fabric.themeColorOpacity,
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#triangle');
  $(document).on('click', '#triangle', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addTriangle({
      width: 150,
      height: 150,
      fill: M4CGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: M4CGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
      themeColorOpacity: MatgenGlobal.editor.cur().fabric.themeColorOpacity,
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#ellipse');
  $(document).on('click', '#ellipse', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addEllipse({
      rx: 150,
      ry: 75,
      fill: M4CGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: M4CGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
      themeColorOpacity: MatgenGlobal.editor.cur().fabric.themeColorOpacity,
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#polygon');
  $(document).on('click', '#polygon', e => {
    e.preventDefault();

    MatgenGlobal.editor.loadDrawingCanvas({
      fill: M4CGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: M4CGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
      themeColorOpacity: MatgenGlobal.editor.cur().fabric.themeColorOpacity,
    });

    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#cancel-drawing');
  $(document).on('click', '#cancel-drawing', e => {
    e.preventDefault();
    $('#drawing-options-div').replaceWith(MatgenGlobal.sidebarAddButton);
    $('#drawing-canvas-container').remove();
  });

  $(document).off('click', '#add-shapes');
  $(document).on('click', '#add-shapes', e => {
    e.preventDefault();
    const objects = MatgenGlobal.drawingCanvas.canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      objects[i].clone(clone => {
        MatgenGlobal.editor.cur().fabric.add(clone);
      });
    }
    $('#drawing-options-div').replaceWith(MatgenGlobal.sidebarAddButton);
    $('#drawing-canvas-container').remove();
  });

  $(document).off('click', '#template-508');
  $(document).on('click', '#template-508', e => {
    e.preventDefault();
    SidebarHeader.modal508($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '.matgen-help');
  $(document).on('click', '.matgen-help', e => {
    e.preventDefault();
    let id = $(e.currentTarget).attr('id');
    if (!id) {
      id = 'help-basics';
    }
    SidebarHeader.helpPage(id);
  });

  $(document).off('click', '.drop-delete');
  $(document).on('click', '.drop-delete', async e => {
    e.stopPropagation();
    e.preventDefault();
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting template page',
      promise: MatgenGlobal.Data.deleteTemplatePage(
        $(e.currentTarget)
          .parent()
          .attr('data-id')
      ),
    });
    MatgenGlobal.sidebar.refresh(
      MatgenGlobal.sidebar,
      null,
      'drop-delete',
      false
    );
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(MatgenGlobal.editor.templateId),
    });
    const pages = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading pages',
      promise: MatgenGlobal.Data.getPages(MatgenGlobal.editor.templateId),
    });
    pages.sort((a, b) => a.number - b.number);
    $('#sidebar-header').replaceWith(
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading sidebar header',
        promise: SidebarHeader.sidebarHeader(
          template,
          pages,
          MatgenGlobal.editor.templateId
        ),
      })
    );
    MatgenGlobal.UI.loadEditor(MatgenGlobal.editor.templateId, pages[0].id);
  });

  $(document).off('click', '#reference-image');
  $(document).on('click', '#reference-image', () => {
    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('file-input').files[0]) {
        MatgenGlobal.editor
          .cur()
          .loadImg(
            document.getElementById('file-input').files[0],
            ({ dataURI }) => {
              sessionStorage.setItem(
                'matgen-reference-img',
                JSON.stringify(dataURI)
              );
              MatgenGlobal.editor.loadReferenceCanvas({
                src: dataURI,
              });
            }
          );
      }
      document
        .querySelector('body')
        .removeChild(document.getElementById('file-input'));
    });
  });

  $(document).off('click', '#show-grid');
  $(document).on('click', '#show-grid', () => {
    MatgenGlobal.UI.showGridForm();
  });

  $(document).off('click', '#theme-color');
  $(document).on('click', '#theme-color', () => {
    MatgenGlobal.UI.showThemeColorForm();
  });

  $(document).off('click', '#inputUseThemeColorShape');
  $(document).on('click', '#inputUseThemeColorShape', e => {
    if ($(e.target).is(':checked')) {
      if (!MatgenGlobal.editor.cur().fabric.themeColor) {
        MatgenGlobal.UI.handleError(
          'Theme color not set',
          '<p>You must set a default theme color before applying it.</p>'
        );
      } else {
        $('#themeColorShapeOpacityContainer').css('display', 'flex');
        $('#themeColorShapeOpacityNumber').val(
          $('#themeColorShapeOpacity').val()
        );
        M4CGlobal.pickr.shape.setColor(
          `${MatgenGlobal.editor.cur().fabric.themeColor.substring(0, 7)}${
            MatgenGlobal.editor.cur().fabric.themeColorOpacity
              ? MatgenGlobal.editor.cur().fabric.themeColorOpacity
              : 'FF'
          }`
        );
      }
    } else {
      $('#themeColorShapeOpacityContainer').css('display', 'none');
    }
  });

  $(document).off('click', '#inputUseThemeColorText');
  $(document).on('click', '#inputUseThemeColorText', e => {
    if ($(e.target).is(':checked')) {
      if (!MatgenGlobal.editor.cur().fabric.themeColor) {
        MatgenGlobal.UI.handleError(
          'Theme color not set',
          '<p>You must set a default theme color before applying it.</p>'
        );
      } else {
        M4CGlobal.pickr.text2.setColor(
          MatgenGlobal.editor.cur().fabric.themeColor
        );
      }
    }
  });
};

export { init };
