/*

CollapsibleLists.js

An object allowing lists to dynamically expand and collapse

Created by Kate Morley - http://code.iamkate.com/ - and released under the terms
of the CC0 1.0 Universal legal code:

http://creativecommons.org/publicdomain/zero/1.0/legalcode

*/

class CollapsibleLists {
  // Makes all lists with the class 'collapsibleList' collapsible. The
  // parameter is:
  //
  // doNotRecurse - true if sub-lists should not be made collapsible
  apply(doNotRecurse = false) {
    [].forEach.call(document.getElementsByTagName('ul'), node => {
      if (node.classList.contains('collapsibleList')) {
        CollapsibleLists.applyTo(node, true);

        if (!doNotRecurse) {
          [].forEach.call(node.getElementsByTagName('ul'), subnode => {
            subnode.classList.add('collapsibleList');
          });
        }
      }
    });
  }

  // Makes the specified list collapsible. The parameters are:
  //
  // node         - the list element
  // doNotRecurse - true if sub-lists should not be made collapsible
  static applyTo(node, doNotRecurse = false) {
    [].forEach.call(node.getElementsByTagName('li'), li => {
      if (!doNotRecurse || node === li.parentNode) {
        li.style.userSelect = 'none';
        li.style.MozUserSelect = 'none';
        li.style.msUserSelect = 'none';
        li.style.WebkitUserSelect = 'none';

        li.addEventListener(
          'click',
          CollapsibleLists.handleClick.bind(null, li)
        );

        CollapsibleLists.toggle(li);
      }
    });
  }

  // Handles a click. The parameter is:
  //
  // node - the node for which clicks are being handled
  static handleClick(node, e) {
    //e.stopPropagation();
    if (e.target.classList && e.target.classList.contains('layer-action')) {
      return false;
    }
    /*let id = false;
    if (node.getAttribute('id')) {
      id = node.getAttribute('id');
    } else {
      const nearestObject = e.target.closest('ul.oe-item[id]');
      id = nearestObject.getAttribute('id');
    }*/
    /*
    const data = e.target.closest('[data-type]').dataset;

    const detail = {
      type: data.type,
      key: data.key,
      value: data.value,
      path: data.path,
    };

    document.dispatchEvent(
      new CustomEvent('object-explorer-click', {
        composed: true,
        detail,
      })
    );*/

    let li = e.target;
    while (li.nodeName !== 'LI') {
      li = li.parentNode;
    }

    if (li === node) {
      CollapsibleLists.toggle(node, true);
    }
  }

  // Opens or closes the unordered list elements directly within the
  // specified node. The parameter is:
  //
  // node - the node containing the unordered list elements
  static toggle(node, saveState = false) {
    if (!node) {
      console.error(Error('Undefined node in toggle call'));
      return false;
    }
    let openItems = sessionStorage.getItem('matgen-tree-state');
    if (!openItems) {
      openItems = [];
    } else {
      openItems = JSON.parse(openItems);
    }
    //console.log('session state:', openItems);

    const open = node.classList.contains('collapsibleListClosed');
    const uls = node.getElementsByTagName('ul');
    //console.log(node, node.classList, open, uls);
    [].forEach.call(uls, ul => {
      let li = ul;
      while (li.nodeName !== 'LI') {
        li = li.parentNode;
      }

      if (li === node) {
        ul.style.display = open ? 'block' : 'none';
      }
    });

    node.classList.remove('collapsibleListOpen');
    node.classList.remove('collapsibleListClosed');

    if (uls.length > 0) {
      node.classList.add(`collapsibleList${open ? 'Open' : 'Closed'}`);
    }
    if (saveState) {
      const index = openItems.indexOf(node.id);
      if (open) {
        if (index === -1) {
          openItems.push(node.id);
        }
      } else {
        if (index !== -1) {
          openItems.splice(index, 1);
        }
      }
      //console.log('save state:', openItems);
      sessionStorage.setItem('matgen-tree-state', JSON.stringify(openItems));
    }
  }
}

export { CollapsibleLists };
