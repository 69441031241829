/* global MatgenGlobal */

import M4CLoaderManagerLoader from './M4CLoaderManagerLoader.js';

const initError = e => `Loader Manager module: ${e} not initialized.`;
const argError = (f, e) =>
  `Loader Manager module ${f}(): ${e} attribute missing.`;

class M4CLoaderManager {
  constructor() {
    if (!MatgenGlobal) {
      throw new Error(initError('MatgenGlobal'));
    }
    if (!MatgenGlobal.tenant_env) {
      throw new Error(initError('MatgenGlobal.tenant_env'));
    }
    if (!MatgenGlobal.api_env) {
      throw new Error(initError('MatgenGlobal.api_env'));
    }
    this.loaders = [];
    this.loaderArray = [];
    this.loaderData = {};
    this.functionTree = {};
    this.tree = {};
    //this.gps = new StackTraceGPS();
  }

  addLoader({ loader, id, debug } = {}) {
    if (!loader) {
      throw new Error(argError('addLoader', 'loader'));
    }
    let matgenLoader;
    const existing = this.loaders.find(l => l.id === id);
    if (!existing) {
      matgenLoader = new M4CLoaderManagerLoader({
        loader,
        id,
        debug,
        manager: this,
      });
      this.loaders.push(matgenLoader);
    } else {
      matgenLoader = existing;
    }
    return matgenLoader;
  }
  removeLoader({ id } = {}) {
    if (!id) {
      throw new Error(argError('removeLoader', 'id'));
    }
  }
}

export default M4CLoaderManager;
