/* global MatgenGlobal, $ */

import MatgenUIFunctions from '../../matgen-ui/ui-functions.js';

export default {
  test: {
    hash: 'test',
    route: '/test',
    action: () => {
      MatgenGlobal.UI.showPage(
        '<div id="test-div" style="width:250px;margin:auto;"></div><div id="test-div-2" style="width:250px;margin:auto;"></div>'
      );
    },
  },
  templates: {
    hash: 'templates',
    route: '/templates',
    action: () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tenant_id = urlParams.get('tenant_id');
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (tenant_id) {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MaterialTemplateTable.getHTML(),
          async () => {
            const data = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading template table data',
              promise: MatgenGlobal.Data.getTemplates(tenant_id),
            });
            MatgenGlobal.Tables.MaterialTemplateTable.load(data);
          }
        );
      } else {
        if (
          MatgenGlobal.AuthUser.user &&
          MatgenGlobal.AuthUser.getUserRole() === 'user'
        ) {
          MatgenGlobal.Router.goTo('/home');
        } else {
          MatgenGlobal.UI.showPage(
            MatgenGlobal.Tables.TemplateTable.getHTML(),
            () => {
              if (
                MatgenGlobal.AuthUser.getUserRole() === 'admin' &&
                MatgenGlobal.Tables.TemplateTable.columns.findIndex(
                  c => c.data === 'tenant_name'
                ) >= 0
              ) {
                MatgenGlobal.Tables.TemplateTable.columns.splice(
                  MatgenGlobal.Tables.TemplateTable.columns.findIndex(
                    c => c.data === 'tenant_name'
                  ),
                  1
                );
                MatgenGlobal.Tables.TemplateTable.columns.find(
                  c => c.data === 'id'
                ).visible = false;
              }
              if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
                $(`
                  <button id="template-import-button" type="button" data-action="import-template" class="btn btn-primary float-end table-action" data-bs-toggle="tooltip" data-placement="auto" title="" data-bs-original-title="Import template" style="margin-right:.5em;">Import</button>
                `).insertBefore($('button[data-action="new-template"]'));
              }
              MatgenGlobal.Tables.TemplateTable.load();
            }
          );
        }
      }
    },
  },
  tenants: {
    hash: 'tenants',
    route: '/tenants',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.TenantTable.getHTML(),
          () => {
            MatgenGlobal.Tables.TenantTable.load();
          }
        );
      }
    },
  },
  matgenQuestionnaire: {
    hash: 'matgenQuestionnaires',
    route: '/matgen-questionnaires',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MatgenQuestionnaireTable.getHTML(),
          () => {
            MatgenGlobal.Tables.MatgenQuestionnaireTable.load();
          }
        );
      }
    },
  },
  userContentQuestionnaire: {
    hash: 'userContentQuestionnaires',
    route: '/user-content-questionnaires',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.UserContentQuestionnaireTable.getHTML(),
          () => {
            MatgenGlobal.Tables.UserContentQuestionnaireTable.load();
          }
        );
      }
    },
  },
  section: {
    hash: 'sections',
    route: '/sections',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.SectionsTable.getHTML(),
          () => {
            MatgenGlobal.Tables.SectionsTable.load();
          }
        );
      }
    },
  },
  questions: {
    hash: 'questions',
    route: '/questions',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.QuestionsTable.getHTML(),
          () => {
            MatgenGlobal.Tables.QuestionsTable.load();
          }
        );
      }
    },
  },
  answers: {
    hash: 'answers',
    route: '/answers',
    action: () => {
      if (!MatgenGlobal.AuthUser.user) {
        if (
          MatgenGlobal.RootRoute &&
          typeof MatgenGlobal.RootRoute === 'function'
        ) {
          MatgenGlobal.RootRoute();
        } else {
          MatgenGlobal.Router.goTo('/unauth');
        }
      } else if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        //TODO
        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.AnswersTable.getHTML(),
          () => {
            MatgenGlobal.Tables.AnswersTable.load();
          }
        );
      }
    },
  },
  templateSummary: {
    hash: 'template-summary',
    route: '/template-summary',
    action: () => {
      MatgenGlobal.UI.showPage(
        MatgenGlobal.Tables.MaterialTemplateTable.getHTML(),
        async () => {
          const data = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading template table data',
            promise: MatgenGlobal.Data.getTemplates(),
          });
          MatgenGlobal.Tables.TemplateSummaryTable.load(data);
        }
      );
    },
  },
  users: {
    hash: 'users',
    route: '/users',
    action: () => {
      MatgenGlobal.Router.core.emitRouteEvent('users');
      if (
        MatgenGlobal.AuthUser.user &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        MatgenGlobal.Router.goTo('/home');
      } else {
        MatgenGlobal.UI.userManagement();
      }
    },
  },
  templateEdit: {
    hash: 'templates/:id',
    route: '/templates/:id',
    after: () => {
      $(MatgenGlobal.ControllerTargetSelector).addClass('editor');
    },
    leave: done => {
      $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
      $('#sidebar').remove();
      done();
    },
    action: req => MatgenGlobal.UI.showTemplateEditor(req.id),
  },
  tenantEdit: {
    hash: 'tenant/:id',
    route: '/tenant/:id',
    action: req => MatgenGlobal.UI.showTenantEditor(req.id),
  },
  tenantQuestionnaire: {
    hash: 'tenant-questionnaire',
    route: '/tenant-questionnaire',
    action: () => MatgenUIFunctions.createQuestionnaire(),
  },
  editQuestionnaire: {
    hash: 'edit-questionnaire/:id',
    route: '/edit-questionnaire/:id',
    action: req => MatgenUIFunctions.editQuestionnaire(req.id),
  },
  tags: {
    hash: 'tags/:id',
    route: '/tags/:id',
    action: req => MatgenGlobal.UI.loadTagger(req.id),
  },
  inspector: {
    hash: 'inspect/:id',
    route: '/inspect/{id}',
    action: req => MatgenGlobal.UI.loadInspector(req.id),
  },
  optionOrder: {
    hash: 'option-order/:id',
    route: '/option-order/{id}',
    action: req => MatgenGlobal.UI.loadOptionSort(req.id),
  },
  materials: {
    hash: 'materials',
    route: '/materials',
    action: () => {
      if (MatgenGlobal.AuthUser && MatgenGlobal.AuthUser.user) {
        MatgenGlobal.Router.core.emitRouteEvent('materials');

        MatgenGlobal.UI.showPage(
          MatgenGlobal.Tables.MaterialTable.getHTML(),
          () => {
            MatgenGlobal.Tables.MaterialTable.load();
          }
        );
      } else {
        MatgenGlobal.Router.goTo('unauth');
      }
    },
  },
  microsites: {
    hash: 'microsites',
    route: '/microsites',
    action: () => {
      MatgenGlobal.Router.core.emitRouteEvent('microsites');
      MatgenGlobal.UI.showPage(
        MatgenGlobal.Tables.MicrositeTable.getHTML(),
        () => {
          MatgenGlobal.Tables.MicrositeTable.load();
        }
      );
    },
  },
  materialEdit: {
    hash: 'materials/:id',
    route: '/materials/:id',
    action: req => MatgenGlobal.UI.showMaterialEditor(req.id),
  },
  unauth: {
    hash: 'unauth',
    route: '/unauth',
    action: () => MatgenGlobal.UI.unauth(),
  },
  appError: {
    hash: 'error',
    route: '/error',
    action: () => MatgenGlobal.UI.appError(),
  },
  library: {
    hash: 'library',
    route: '/library',
    action: () => MatgenGlobal.UI.library(),
  },
};
