/* global $, MatgenGlobal */

import { v4 as UUID } from 'uuid';
import { CollapsibleLists } from '../../../matgen-ui/common/collapsible-lists.js';
import { UI } from '../../../matgen-ui/ui.js';
import { init as initEvents } from './events/sidebar-events.js';
import { MatgenEditor } from '../../../core/matgen-editor.js';
import { emit } from '../../../matgen-ui/common/helpers.js';
import { dragElement } from './drag.js';
import { SidebarDisplay } from './SidebarDisplay.js';
import { SidebarHeader } from './SidebarHeader.js';

class Sidebar {
  constructor() {
    initEvents(this);
  }

  async refresh(_this, componentId, caller, dirty = true) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Rendering sidebar',
      promise: _this.render(),
    });
    if (
      !MatgenGlobal.settingOption ||
      (MatgenGlobal.settingOption.removed && MatgenGlobal.settingOption.added)
    ) {
      if (dirty && !MatgenGlobal.ignoreDirty) {
        if (componentId && componentId !== 'undefined') {
          _this.markComponentDirty(componentId);
        } else {
          _this.markTemplateDirty();
        }
      } else {
        MatgenGlobal.ignoreDirty = false;
      }
    } else if (MatgenGlobal.settingOption) {
      if (caller === 'object:removed') {
        MatgenGlobal.settingOption.removed = true;
      }
      if (caller === 'object:added') {
        MatgenGlobal.settingOption.added = true;
      }
    } else {
      MatgenGlobal.ignoreDirty = false;
      MatgenGlobal.settingOption = false;
    }
  }

  nameEdit(e, _this) {
    const name = $(e.target)
      .text()
      .trim();
    const id = $(e.target)
      .attr('id')
      .replace(/node-|-name/g, '');

    const objects = MatgenGlobal.editor.cur().fabric.getObjects();

    if (id.includes('gcid')) {
      objects.forEach(o => {
        if (o.componentId === id.replace('gcid-', '')) {
          o.componentName = name;
        }
      });
      MatgenGlobal.editor.cur().fabric.renderAll();
      _this.markComponentDirty(id.replace('gcid-', ''));
    } else {
      const obj = MatgenGlobal.UI.findById(objects, id);

      if (obj) {
        obj.name = name;
        MatgenGlobal.editor.cur().fabric.renderAll();
        if (obj.componentId) {
          _this.markComponentDirty(obj.componentId);
        } else {
          _this.markTemplateDirty();
        }
      }
    }
  }

  async loadPage(e) {
    MatgenGlobal.templateDirty = false;
    const id = $(e.target).attr('data-id');
    const template_id = MatgenGlobal.editor.templateId;
    UI.loadEditor(template_id, id);
  }

  async addPage() {
    MatgenGlobal.templateDirty = false;
    const id = UUID();
    const template_id = MatgenGlobal.editor.templateId;
    const width = MatgenGlobal.editor.width;
    const height = MatgenGlobal.editor.height;
    const pages = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading pages',
      promise: MatgenGlobal.Data.getPages(template_id),
    });
    const maxPage = Math.max.apply(
      Math,
      pages.map(o => {
        return o.number;
      })
    );
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving page',
      promise: MatgenGlobal.Data.savePage({
        id,
        template_id,
        number: maxPage + 1,
      }),
    });

    MatgenGlobal.editor = new MatgenEditor({
      id: template_id,
      pageId: id,
      templateId: template_id,
    });

    MatgenGlobal.editor.width = width;
    MatgenGlobal.editor.height = height;

    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Initializing editor',
      promise: MatgenGlobal.editor.init(
        `matgen-scale-container-${template_id}`
      ),
    });
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(template_id),
    });
    const pageObj = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading page object',
      promise: UI.getPageObject(),
    });
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving page JSON',
      promise: MatgenGlobal.Data.savePageFile(id, pageObj, template.tenant_id),
    });
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving page preview',
      promise: UI.savePagePreview(false, template.tenant_id),
    });
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading editor',
      promise: UI.loadEditor(template_id, id),
    });
  }

  markComponentDirty(componentId) {
    if (!MatgenGlobal.saving) {
      window.setTimeout(() => {
        $(`.component-save[data-component-id="${componentId}"]`)
          .addClass('text-danger')
          .find('.component-dirty')
          .show();
      }, 250);
      MatgenGlobal.componentDirty = true;
    }
  }

  markTemplateDirty() {
    $('#template-save, #material-save')
      .removeClass('btn-secondary')
      .addClass('btn-danger')
      .find('.dirty')
      .css('display', 'inline-block');
    $('#extra-save-button')
      .removeClass('btn-primary')
      .addClass('btn-danger');
    MatgenGlobal.templateDirty = true;
  }

  markTemplateClean() {
    $('#template-save, #material-save')
      .removeClass('btn-danger')
      .addClass('btn-secondary')
      .find('.dirty')
      .hide();
    $('#extra-save-button')
      .removeClass('btn-danger')
      .addClass('btn-primary');
    MatgenGlobal.templateDirty = false;
  }

  markComponentClean(componentId) {
    window.setTimeout(() => {
      $(`.component-save[data-component-id="${componentId}"]`)
        .removeClass('text-danger')
        .find('.component-dirty')
        .css('display', 'none');
    }, 250);
    MatgenGlobal.componentDirty = false;
  }

  async buildTree(objects, isBranch = false) {
    objects.sort(MatgenGlobal.MatgenUIFunctions.readOrderSort);
    try {
      const template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(MatgenGlobal.editor.templateId),
      });

      let tenant = false;
      if (MatgenGlobal.tenant_id) {
        tenant = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading tenant',
          promise: MatgenGlobal.Data.getTenant(MatgenGlobal.tenant_id),
        });
      } else {
        const tenant_id = template.tenant_id;
        tenant = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading tenant',
          promise: MatgenGlobal.Data.getTenant(tenant_id),
        });
      }

      const nodes = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading sidebar nodes',
        group: 'build-sidebar',
        promise: Promise.all(
          objects
            .filter(o => {
              let show = false;
              if (
                !o.sidebarIgnore &&
                o.selectable &&
                (MatgenGlobal.AuthUser.isM4CSuper() ||
                  o.componentId ||
                  o.uploader ||
                  o.userEditable ||
                  o.groupId ||
                  o.studyDataConnection ||
                  o.richText)
              ) {
                show = true;
              }
              if (!o.selectable && o.layerLocked) {
                show = true;
              }
              return show;
            })
            .map(o => Sidebar.displayNode(o, this, isBranch, template, tenant))
        ),
      });

      return nodes.join('');
    } catch (e) {
      console.error(e);
    }
  }

  static async nodeIcons(node, tree, isBranch, template, tenant) {
    let actions = [];
    let componentActions = [];
    if (
      MatgenGlobal.AuthUser.isM4CSuper() &&
      template.type === 'PDF' &&
      !MatgenGlobal.Suppress508 &&
      tenant.is508 === 1
    ) {
      actions.push(SidebarDisplay.getAction('object-accessible', node));
    }

    if (MatgenGlobal.AuthUser.getUserRole() === 'user' && node.uploader) {
      actions.push(SidebarDisplay.getAction('object-visible', node));
    }

    if (MatgenGlobal.AuthUser.isM4CSuper()) {
      actions.push(SidebarDisplay.getAction('object-study-data', node));
    }

    if (
      MatgenGlobal.AuthUser.isM4CSuper() &&
      !node.componentId &&
      !node.uploader &&
      !node.userEditable &&
      !node.groupId
    ) {
      actions.push(SidebarDisplay.getAction('template-make-component', node));
    }

    if (MatgenGlobal.AuthUser.isM4CSuper()) {
      actions.push(SidebarDisplay.getAction('object-locked', node));
      if (
        template.type === 'PDF' &&
        !MatgenGlobal.Suppress508 &&
        tenant.is508 === 1
      ) {
        if (node.type !== 'group-component') {
          componentActions.push(
            SidebarDisplay.getAction('object-accessible-component', node)
          );
        } else {
          componentActions.push(
            SidebarDisplay.getAction('object-accessible-group-component', node)
          );
        }
      }

      if (node.type === 'textbox') {
        componentActions.push(
          SidebarDisplay.getAction('component-uploads', node)
        );
        componentActions.push(SidebarDisplay.getAction('component-edit', node));
      }

      if (node.type === 'image' && !node.uploader) {
        componentActions.push(
          SidebarDisplay.getAction('component-uploads', node)
        );
      }

      componentActions.push(SidebarDisplay.getAction('component-save', node));
      componentActions.push(SidebarDisplay.getAction('object-visible', node));
      componentActions.push(SidebarDisplay.getAction('object-locked', node));
      componentActions.push(SidebarDisplay.getAction('delete-component', node));
    }

    if (MatgenGlobal.AuthUser.getUserRole() === 'admin') {
      componentActions.push(SidebarDisplay.getAction('component-save', node));
    }

    if (node.componentId && MatgenGlobal.AuthUser.getUserRole() !== 'user') {
      const options = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading component options',
        promise: MatgenGlobal.Data.getComponentOptions(node.componentId),
      });
      if (options && options.length > 1) {
        componentActions.push(
          SidebarDisplay.getAction('manage-options', node, options)
        );
      }
    }

    if (
      ['circle', 'ellipse', 'rect', 'triangle', 'polygon'].includes(node.type)
    ) {
      if (
        MatgenGlobal.AuthUser.isM4CSuper() &&
        (isBranch || (!node.componentId && !node.groupId))
      ) {
        actions.push(SidebarDisplay.getAction('delete-object', node));
      }
    }

    let icon, nodeType;
    switch (node.type) {
      default:
        break;
      case 'circle':
        nodeType = 'Circle';
        icon = '<i class="fas fa-xs fa-circle"></i>';
        break;
      case 'rect':
        nodeType = 'Rectangle';
        icon = '<i class="fas fa-xs fa-rectangle"></i>';
        break;
      case 'triangle':
        nodeType = 'Triangle';
        icon = '<i class="fas fa-xs fa-triangle"></i>';
        break;
      case 'ellipse':
        nodeType = 'Ellipse';
        icon = '<i class="fas fa-xs fa-vector-circle"></i>';
        break;
      case 'polygon':
        nodeType = 'Polygon';
        icon = '<i class="fas fa-xs fa-draw-polygon"></i>';
        break;
      case 'image':
        nodeType = node.uploader ? 'Uploader' : 'Img';
        icon = node.uploader
          ? '<i class="fas fa-xs fa-upload"></i>'
          : '<i class="fas fa-xs fa-image"></i>';
        if (!node.uploader && !node.groupId && !node.componentId) {
          if (MatgenGlobal.AuthUser.isM4CSuper()) {
            actions.push(SidebarDisplay.getAction('make-uploader', node));

            if (!node.groupId) {
              actions.push(SidebarDisplay.getAction('delete-object', node));
            }
          }
        } else if (node.uploader && MatgenGlobal.AuthUser.isM4CSuper()) {
          actions.push(SidebarDisplay.getAction('delete-object', node));
        } else {
          if (!isBranch && MatgenGlobal.AuthUser.getUserRole() !== 'user') {
            nodeType = `${nodeType} Comp.`;
            icon += '<i class="fas fa-xs fa-sitemap"></i>';
          }
          if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
            componentActions.push(SidebarDisplay.getAction('add-option', node));
          }
        }
        break;
      case 'group':
        nodeType = 'Grp.';
        icon = '<i class="fas fa-xs fa-object-group"></i>';
        if (
          !node.componentId &&
          !node.groupId &&
          MatgenGlobal.AuthUser.isM4CSuper()
        ) {
          actions.push(SidebarDisplay.getAction('delete-object', node));
        }
        break;
      case 'textbox':
        nodeType = node.userEditable ? 'User Text' : 'Text';
        icon = node.userEditable
          ? '<i class="fas fa-xs fa-edit"></i>'
          : '<i class="fas fa-xs fa-text"></i>';
        if (
          node.componentId &&
          !isBranch &&
          MatgenGlobal.AuthUser.getUserRole() !== 'user'
        ) {
          icon += '<i class="fas fa-sitemap"></i>';
          componentActions.push(SidebarDisplay.getAction('edit-text', node));
        }
        if (
          MatgenGlobal.AuthUser.isM4CSuper() &&
          (isBranch || (!node.componentId && !node.groupId))
        ) {
          actions.push(SidebarDisplay.getAction('edit-text', node));
          actions.push(SidebarDisplay.getAction('delete-object', node));
        }
        break;
      case 'group-component':
        nodeType = 'Grp.';
        icon = '<i class="fas fa-xs fa-object-group"></i>';
        if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
          nodeType = `${nodeType} Comp.`;
          icon += '<i class="fas fa-sitemap"></i>';
        }

        if (MatgenGlobal.AuthUser.isM4CSuper()) {
          componentActions.push(
            SidebarDisplay.getAction('component-edit-group', node)
          );
        }

        break;
    }

    const hasChildren =
      node.getObjects &&
      node.getObjects() &&
      MatgenGlobal.AuthUser.getUserRole() !== 'user';
    const childTree = hasChildren
      ? await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading sidebar sub-tree',
          promise: tree.buildTree(
            node.getObjects().filter(o => !o.sidebarIgnore),
            true
          ),
        })
      : '';

    if (
      node.type === 'group-component' ||
      ((node.type === 'image' || node.type === 'textbox') &&
        node.componentId &&
        !isBranch)
    ) {
      actions = componentActions;
    }
    actions.sort((a, b) => a.sort_order - b.sort_order);
    actions = actions.map(a => a.markup);
    componentActions.sort((a, b) => a.sort_order - b.sort_order);
    componentActions = componentActions.map(a => a.markup);
    if (MatgenGlobal.AuthUser.getUserRole() === 'user' && node.uploader) {
      actions = [
        `
          <a class="btn btn-outline-secondary layer-action user-toggle-object hide-btn" data-id="${
            node.id
          }" href="#" data-bs-toggle="tooltip" data-hidden="false" title="Hide item from finished products">
            ${
              node.visible
                ? 'Hide <i class="fa-regular fa-eye-slash fa-sm"></i>'
                : 'Show <i class="fa-regular fa-eye fa-sm"></i>'
            }
          </a>
        `,
      ];
    }
    return {
      actions,
      icon,
      nodeType,
      childTree,
      hasChildren,
      componentActions,
    };
  }

  static async displayNode(node, tree, isBranch, template, tenant) {
    let actionsResponse;
    if (
      node.richTextSizer === true &&
      MatgenGlobal.AuthUser.getUserRole() === 'super'
    ) {
      actionsResponse = {
        actions: [
          SidebarDisplay.getAction('create-rich-text', node),
          SidebarDisplay.getAction('delete-object', node),
        ],
        icon: '<i class="fas fa-xs fa-code"></i>',
        nodeType: 'Rich Text Sizer',
      };
    } else if (node.richText === true) {
      let actions = [];
      if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
        actions = [
          SidebarDisplay.getAction('mark-rich-text-uneditable', node),
          SidebarDisplay.getAction('edit-rich-text', node),
          SidebarDisplay.getAction('object-study-data', node),
          SidebarDisplay.getAction('object-visible', node),
          SidebarDisplay.getAction('object-locked', node),
          SidebarDisplay.getAction('delete-object', node),
        ];
      }
      actionsResponse = {
        actions,
        icon: '<i class="fas fa-xs fa-code"></i>',
        nodeType: 'Rich Text',
      };
      if (
        template.type === 'PDF' &&
        !MatgenGlobal.Suppress508 &&
        tenant.is508 === 1 &&
        MatgenGlobal.AuthUser.getUserRole() === 'super'
      ) {
        actionsResponse.actions.unshift(
          SidebarDisplay.getAction('object-accessible', node)
        );
      }
    } else {
      actionsResponse = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading action icons',
        promise: this.nodeIcons(node, tree, isBranch, template, tenant),
      });
    }
    if (
      actionsResponse.actions &&
      actionsResponse.actions[0] &&
      actionsResponse.actions[0].sort_order
    ) {
      actionsResponse.actions.sort((a, b) => a.sort_order - b.sort_order);
      actionsResponse.actions = actionsResponse.actions.map(a => a.markup);
    }
    if (
      actionsResponse.componentActions &&
      actionsResponse.componentActions[0] &&
      actionsResponse.componentActions[0].sort_order
    ) {
      actionsResponse.componentActions.sort(
        (a, b) => a.sort_order - b.sort_order
      );
      actionsResponse.componentActions = actionsResponse.componentActions.map(
        a => a.markup
      );
    }
    const { actions } = actionsResponse;
    const {
      icon,
      nodeType,
      childTree,
      hasChildren,
      componentActions,
    } = actionsResponse;
    const nodeHTML = `
    <li ${
      MatgenGlobal.AuthUser.getUserRole() === 'user' ? 'tabindex="0"' : ''
    } id="${node.id}" data-id="${node.id}" ${
      node.componentId ? `data-component-id="${node.componentId}"` : ''
    } class="tree-li list-group-item ${
      node.componentId && !isBranch ? 'component' : ''
    } ${node.richText ? 'rich-text' : node.type}" ${
      node.studyDataConnection
        ? `data-study-data="${node.studyDataConnection}"`
        : ''
    }>
      <div class="node-content">
        <div class="node-info">
          <div class="node-top">

            <div class="node-type">
              ${
                MatgenGlobal.AuthUser.getUserRole() !== 'user'
                  ? `<div class="node-label">${nodeType}</div>`
                  : ''
              }
              <div class="node-icons">
                ${
                  MatgenGlobal.null && MatgenGlobal.AuthUser.isM4CSuper()
                    ? `<div class="node-grip">
                  <i class="fas fa-arrows"></i>
                </div>`
                    : ''
                }
                <div class="node-toggle">
                  <i class="open-btn fas fa-caret-right"></i>
                  <i class="close-btn fas fa-caret-down"></i>
                  <i class="no-btn"></i>
                </div>
                <div class="node-icon">
                  ${icon}
                </div>
              </div>
            </div>

            <div id="node-${node.id}-name" class="node-name" ${
      MatgenGlobal.AuthUser.getUserRole() !== 'user' ? 'contenteditable' : ''
    }>
              ${node.name ? node.name : node.type}
            </div>

            ${
              MatgenGlobal.AuthUser.getUserRole() === 'user'
                ? `${actions.map(a => a).join('')}`
                : ''
            }
          </div>
          ${
            MatgenGlobal.AuthUser.getUserRole() !== 'user'
              ? `
            <div class="node-bottom">
              <div class="node-actions">
                ${actions.map(a => a).join('')}
              </div>
            </div>
          `
              : ''
          }
        </div>
      </div>
      ${
        hasChildren
          ? `
          <ul class="list-group" id="${node.id}-children">
            ${childTree}
          </ul>
          `
          : ''
      }
    </li>
    `;

    if (
      node.componentId &&
      !isBranch &&
      node.type !== 'group-component' &&
      node.type !== 'image' &&
      node.type !== 'textbox'
    ) {
      return `
      <li ${
        MatgenGlobal.AuthUser.getUserRole() === 'user' ? 'tabindex="0"' : ''
      } id="${node.componentId}" data-id="${
        node.componentId
      }" data-component-id="${
        node.componentId
      }" class="tree-li list-group-item component">
        <div class="node-content">
          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? `<div class="node-grip">
            <i class="fas fa-arrows"></i>
          </div>`
              : ''
          }
          <div class="node-toggle">
            <i class="open-btn fas fa-caret-right"></i>
            <i class="close-btn fas fa-caret-down"></i>
            <i class="no-btn"></i>
          </div>
          <div class="node-icon">
            ${icon}
            <i class="fas fa-sitemap"></i>
          </div>
          <div id="node-${node.id}-name" class="node-name" ${
        MatgenGlobal.AuthUser.getUserRole() !== 'user' ? 'contenteditable' : ''
      }>
            ${
              node.name
                ? node.name
                : `${node.type.charAt(0).toUpperCase() +
                    node.type.slice(1)} Component`
            }
          </div>
          <div class="node-actions">
            ${componentActions.map(a => a).join('')}
          </div>
        </div>
          <ul class="list-group" id="${node.id}-children">
            ${nodeHTML}
          </ul>
      </li>
      `;
    }
    return nodeHTML;
  }

  async render() {
    $('#group-make-component').hide();
    if (!MatgenGlobal.FixedSidebar) {
      dragElement(document.getElementById('sidebar'));
    }
    const objects = MatgenGlobal.editor
      .cur()
      .fabric.getObjects()
      .filter(
        o =>
          !o.sidebarIgnore &&
          !(o.uneditable && MatgenGlobal.AuthUser.getUserRole() === 'user')
      );
    $('#sidebar-tree').empty().append(`
      <li class="tree-li list-group-item">
        <div style="height:2.5em;padding-top:.5em;text-align:center;">
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </li>
    `);
    if (objects.length === 0) {
      $('#sidebar-tree')
        .empty()
        .append(
          $(
            `
          <li class="tree-li list-group-item">
            <div class="node-content">
              <div class="node-toggle">
                <i class="open-btn fas fa-caret-right"></i>
                <i class="close-btn fas fa-caret-down"></i>
              </div>
              <div class="node-icon">
                <i class="fas fa-empty-set"></i>
              </div>
              <div class="node-name">
                ${
                  MatgenGlobal.AuthUser.getUserRole() === 'user'
                    ? 'No editable objects.'
                    : 'No objects. Use the buttons above to add objects.'
                }
              </div>
            </div>
          </li>
          `
          )
        );
      this.init();
    } else {
      const cids = objects
        .map(o => o.componentId)
        .filter((value, index, self) => {
          return value && self.indexOf(value) === index;
        });
      cids.forEach(cid => {
        const objs = objects.filter(o => o.componentId === cid);
        let index = 0;
        let visible = true;
        let selectable = true;
        if (objs.length > 1 || objs[0].type === 'group') {
          objs.forEach(o => {
            index = objects.findIndex(i => i.id === o.id);
            objects.splice(index, 1);
            if (o.layerVisible === false) {
              visible = false;
            }
            if (o.layerLocked === true) {
              selectable = false;
            }
          });
          let cObjects = objs;
          if (objs[0].type === 'group') {
            cObjects = objs[0].getObjects();
          }

          if (MatgenGlobal.AuthUser.hasRole('admin')) {
            objs[0].hasControls = false;
            MatgenGlobal.editor.cur().fabric.renderAll();
          }
          let name, readOrder, allowUploads;
          cObjects.forEach(o => {
            o.groupId = cid;
            if (o.componentName) {
              name = o.componentName;
            }
            allowUploads = o.allowUploads;
            readOrder = o.componentReadOrder
              ? o.componentReadOrder
              : o.readOrder;
          });

          objects.splice(index, 0, {
            id: `gcid-${cid}`,
            componentId: cid,
            objects: cObjects,
            type: 'group-component',
            getObjects: () => cObjects,
            visible,
            selectable,
            name,
            readOrder,
            allowUploads,
          });
        }
      });
      try {
        const tree = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Building sidebar tree',
          promise: this.buildTree(objects.filter(o => !o.sidebarIgnore)),
        });
        if (tree === '') {
          $('#sidebar-tree')
            .empty()
            .append(
              $(
                `
              <li class="tree-li list-group-item">
                <div class="node-content">
                  <div class="node-toggle">
                    <i class="open-btn fas fa-caret-right"></i>
                    <i class="close-btn fas fa-caret-down"></i>
                  </div>
                  <div class="node-icon">
                    <i class="fas fa-empty-set"></i>
                  </div>
                  <div class="node-name">
                    No editable objects.
                  </div>
                </div>
              </li>
              `
              )
            );
          this.init();

          return false;
        }
        $('#sidebar-tree')
          .empty()
          .append($(tree));
        if ($('#sidebar-tree').length > 0) {
          CollapsibleLists.applyTo(document.getElementById('sidebar-tree'));

          const selected = MatgenGlobal.editor.cur().fabric.getActiveObjects();
          selected.forEach(o => {
            $(`#${o.id}`).addClass('active');
            $(`#${o.componentId}`).addClass('active');
          });
          this.init();
          let openItems = sessionStorage.getItem('matgen-tree-state');
          if (!openItems) {
            openItems = [];
          } else {
            openItems = JSON.parse(openItems);
          }
          if (openItems && openItems.length > 0) {
            openItems.forEach(i => {
              $(`#${i}`).addClass('active');
              CollapsibleLists.toggle(document.getElementById(i), true);
            });
          }

          if (MatgenGlobal.AuthUser.isM4CSuper()) {
            $('#show-grid').show();
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  init() {
    emit({ event: 'matgen-sidebar-loading-start' });
    MatgenGlobal.UI.initTooltips();
    $('.dropdown > a').dropdown();
    $('#sidebar-tree').animate({ height: '100%' }, 200);
    if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
      $('#sidebar-title').show();
    }
    if (!MatgenGlobal.SuppressSidebarActions) {
      $('#editor-back-div').remove();
    }
    emit({ event: 'matgen-page-ready' });
    emit({ event: 'matgen-sidebar-loading-stop' });
  }

  static appendIcon(nodeData, div, classes, action) {
    const id = UUID();
    const tmp = $(`<i id="${id}" class="${classes}"></i>`);
    div.append(tmp);
    $(document).on('click', `#${id}`, () => action(nodeData));
  }

  async container(id) {
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading sidebar template',
      group: 'load-sidebar',
      promise: MatgenGlobal.Data.getTemplate(MatgenGlobal.editor.templateId),
    });
    const pages = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading sidebar pages',
      promise: MatgenGlobal.Data.getPages(MatgenGlobal.editor.templateId),
    });
    pages.sort((a, b) => a.number - b.number);
    return `
      <div id="sidebar"
      ${MatgenGlobal.FixedSidebar ? '' : `draggable="true"`}
      ${MatgenGlobal.AuthUser.getUserRole() === 'user' ? ' class="user"' : ''}${
      MatgenGlobal.AuthUser.getUserRole() === 'admin' ? ' class="admin"' : ''
    }>
        <ul id="sidebar-main" class="list-group">
          ${SidebarHeader.sidebarHeader(template, pages, id)}
          <li>
            <ul id="sidebar-tree" class="list-group"></ul>
          </li>

        </ul>
      </div>
    `;
  }
}

export { Sidebar };
