/* global MatgenGlobal */

import { M4CTable } from '../M4CTable.js';
// import { API } from 'aws-amplify';

const QuestionnaireActions = () => {
  const actions = [
    {
      action: 'edit',
      title: 'Edit Questionnaire',
      icon: 'fas fa-edit',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'view-sections',
      title: 'View Sections',
      icon: 'fa-solid fa-section',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'delete',
      title: 'Delete Questionnaire',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];

  return actions;
};

const QuestionnaireColumns = [
  { data: 'id', title: 'ID' },
  { data: 'tenant_id', title: 'Tenant ID' },
  { data: 'name', title: 'Name' },
  { data: 'type', title: 'Type' },
  { data: 'active', title: 'Active' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item =>
      M4CTable.actionsHTML(item, 'questionnaire', QuestionnaireActions(item)),
  },
];

const QuestionnaireTable = new M4CTable({
  columns: QuestionnaireColumns,
  table: 'questionnaire',
  title: 'Questionnaires',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
  arg: window.location.search,
});

const MatgenQuestionnaireTable = new M4CTable({
  columns: QuestionnaireColumns,
  table: 'questionnaire',
  title: 'Matgen Questionnaires',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
  arg: '?type=material',
});

const UserContentQuestionnaireTable = new M4CTable({
  columns: QuestionnaireColumns,
  table: 'questionnaire',
  title: 'User Content Questionnaires',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
  arg: '?type=material&s=1',
});

export {
  QuestionnaireTable,
  MatgenQuestionnaireTable,
  UserContentQuestionnaireTable,
};
