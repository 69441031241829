/* global MatgenGlobal */

class Menu {
  constructor(menu) {
    // Async dependencies - make constructor async
    // by returning a self-executing anonymous function
    return (async () => {
      try {
        let options = menu.actions;
        let sections = false;
        if (menu.sections) {
          options = menu.sections;
          sections = true;
        }
        this.markup = this.navbar(
          this.navdropli(
            `
          ${this.navdroplink(menu.title, menu.id)}
          ${this.navdrop(options, menu.id, menu.classes, sections)}
        `
          )
        );
        this.markupLi = this.navdropli(
          `
          ${this.navdroplink(menu.title, menu.id)}
          ${this.navdrop(options, menu.id, menu.classes, sections)}
        `
        );

        return this; // Return the newly-created instance
      } catch (e) {
        console.error(Error(e));
        return false;
      }
    }).call(this); // Execute with "this" context
  }
  buildDrop(options) {
    return options
      .map(o => {
        if (o.access) {
          if (o.access.tenants) {
            const tenant = MatgenGlobal.AuthUser.getUserTenant();
            if (!tenant || !o.access.tenants.includes(tenant)) {
              return '';
            }
          }
          if (o.access.roles) {
            const groups = MatgenGlobal.AuthUser.getUserGroups() || [];
            const matchedGroups = groups.filter(value =>
              o.access.roles.includes(value)
            );
            if (
              MatgenGlobal.AuthUser.getUserRole() !== 'user' &&
              o.access.roles[0] === 'user'
            ) {
              return '';
            }
            if (matchedGroups.length < 1) {
              return '';
            }
          }
        }
        return `<a class="dropdown-item primary" ${
          o.id ? `id="${o.id}"` : ''
        } href="#">${o.label}</a>`;
      })
      .join('');
  }
  buildNav(options, sections) {
    if (sections) {
      return options
        .map(s => {
          return `
          <h6 class="dropdown-header">${s.title}</h6>
          <div class="dropdown-divider"></div>
          ${this.buildDrop(s.actions)}
            <div class="dropdown-divider"></div>
        `;
        })
        .join('');
    } else {
      return this.buildDrop(options);
    }
  }
  navdrop(options, id, classes, sections) {
    return `
      <div class="dropdown-menu ${classes ? classes : ''} ${
      id === 'user-drop' ? 'dropdown-menu-end' : ''
    }" aria-labelledby="${id}" data-boundary="window">
        ${this.buildNav(options, sections)}
      </div>
    `;
  }
  navdroplink(link, id) {
    return `
      <a class="nav-link dropdown-toggle" href="#" id="${id}" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        ${link}
      </a>
    `;
  }
  navdropli(content) {
    return `
      <li class="nav-item dropdown">
        ${content}
      </li>
    `;
  }
  navbar(content) {
    return `
      <ul class="navbar-nav mr-auto">
        ${content}
      </ul>
    `;
  }
}

export { Menu };
