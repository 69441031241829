/* global MatgenGlobal */

import { M4CTable } from '../M4CTable.js';

const QuestionsActions = () => {
  const actions = [
    {
      action: 'edit',
      title: 'Edit Question',
      icon: 'fas fa-edit',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'view-answers',
      title: 'View Answers',
      icon: 'fa-solid fa-clipboard-check',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'delete',
      title: 'Delete Question',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];

  return actions;
};

const QuestionsColumns = [
  { data: 'id', title: 'ID' },
  { data: 'section_id', title: 'Section ID' },
  { data: 'component', title: 'Component' },
  { data: 'text', title: 'Text' },
  { data: 'sub_text', title: 'subtext' },
  { data: 'sort_order', title: 'Sort Order' },
  { data: 'list_add_name', title: 'List Add Name' },
  { data: 'tooltip', title: 'Tooltip' },
  { data: 'insert_sub_text', title: 'Insert sub Text' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item =>
      M4CTable.actionsHTML(item, 'Questions', QuestionsActions(item)),
  },
];

const QuestionsTable = new M4CTable({
  columns: QuestionsColumns,
  table: 'question',
  title: 'Questions',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
  arg: window.location.search,
});

export { QuestionsTable };
