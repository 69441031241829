/* global $, MatgenGlobal */

import { v4 as UUID } from 'uuid';
import { fabric } from 'fabric';
import { UI } from '../../../../matgen-ui/ui.js';
import MatgenUIFunctions from '../../../../matgen-ui/ui-functions.js';
import { debounce } from 'throttle-debounce';
import { init as initHeaderEvents } from './header.js';
import { SidebarHeader } from '../SidebarHeader.js';

const debouncedRefresh = debounce(
  25,
  false,
  (_this, componentId, caller, dirty = true) => {
    _this.refresh(_this, componentId, caller, dirty);
  }
);

function handleSideBarSelection(e) {
  if (!$(e.currentTarget).attr('data-id')) {
    return false;
  }
  MatgenGlobal.editor.cur().hoverOutline(e, true);
  if ($(e.currentTarget).hasClass('group-component')) {
    MatgenGlobal.suppressHoverOutline = true;
    MatgenGlobal.editor.cur().fabric.discardActiveObject();
    const sel = new fabric.ActiveSelection(
      MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .filter(
          o =>
            o.componentId === $(e.currentTarget).attr('data-component-id') &&
            !o.layerLocked
        ),
      {
        canvas: MatgenGlobal.editor.cur().fabric,
      }
    );
    sel.hasControls = false;
    MatgenGlobal.editor.cur().fabric.setActiveObject(sel);
    MatgenGlobal.editor.cur().fabric.renderAll();
    MatgenGlobal.suppressHoverOutline = false;
  } else {
    const obj = MatgenGlobal.UI.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      $(e.currentTarget).attr('data-id')
    );
    if (!obj) {
      console.error(
        'Object not found for id:',
        $(e.currentTarget).attr('data-id')
      );
    } else {
      if (!obj.layerLocked) {
        MatgenGlobal.editor.cur().fabric.setActiveObject(obj);
        MatgenGlobal.editor.cur().fabric.renderAll();
      }
    }
  }
}

const decode = str => {
  return $('<textarea/>')
    .html(str)
    .text();
};

const init = _this => {
  $(document).ready(() => {
    initHeaderEvents();

    $(document).off('click', '#grid-remove');
    $(document).on('click', '#grid-remove', () => {
      const objects = MatgenGlobal.editor.cur().fabric.getObjects();
      objects.forEach(o => {
        if (o.id.includes('grid-line')) {
          MatgenGlobal.editor.cur().fabric.remove(o);
        }
      });
      MatgenGlobal.editor.cur().fabric.renderAll();
      MatgenGlobal.editor.cur().fabric.off('object:moving');
      $('#grid-modal').modal('hide');
    });

    $(document).off('click', '#slide-submenu');
    $(document).on('click', '#slide-submenu', () => {
      $('#sidebar-main').fadeOut('slide', () => {
        $('.mini-submenu').fadeIn();
      });
    });

    $(document).off('click', '.mini-submenu');
    $(document).on('click', '.mini-submenu', () => {
      $('#sidebar-main').toggle('slide');
      $('.mini-submenu').hide();
    });

    $(document).off('click', '.make-uploader');
    $(document).on('click', '.make-uploader', e => {
      e.preventDefault();
      const obj = MatgenGlobal.UI.findById(
        MatgenGlobal.editor.cur().fabric.getObjects(),
        $(e.currentTarget).attr('data-id')
      );
      if (!obj) {
        console.error(
          'Object not found for id:',
          $(e.currentTarget).attr('data-id')
        );
      } else {
        obj.set('uploader', true);
        obj.set('uploaderWidth', obj.get('width'));
        obj.set('uploaderHeight', obj.get('height'));
        MatgenGlobal.editor.cur().fabric.renderAll();
        MatgenGlobal.sidebar.refresh(
          MatgenGlobal.sidebar,
          null,
          'make-uploader'
        );
      }
    });

    $(document).off('click', '.manage-options');
    $(document).on('click', '.manage-options', async e => {
      e.preventDefault();
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading component option display',
          promise: UI.showComponentOptions($(e.currentTarget).attr('data-id')),
        });
      } catch (e) {
        console.error(e);
        UI.handleError(
          'Error',
          'There was a problem finding the component options.'
        );
      }
    });

    $(document).off('click', '.edit-text');
    $(document).on('click', '.edit-text', e => {
      e.preventDefault();
      if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
        UI.textForm({
          edit: true,
          id: $(e.currentTarget).attr('data-id'),
          componentId: $(e.currentTarget).attr('data-component-id'),
        });
      } else if (MatgenGlobal.AuthUser.getUserRole() === 'admin') {
        UI.adminTextForm({
          edit: true,
          id: $(e.currentTarget).attr('data-id'),
          componentId: $(e.currentTarget).attr('data-component-id'),
          cb: componentId =>
            MatgenGlobal.sidebar.markComponentDirty(componentId),
          target: '#m4c-wrapper',
        });
      }
    });

    $(document).off('click', '.delete-component');
    $(document).on('click', '.delete-component', async e => {
      e.preventDefault();
      const id = $(e.currentTarget).attr('data-id');
      const objects = MatgenGlobal.editor.cur().fabric.getObjects();
      objects.forEach(o => {
        if (o.id === id || o.componentId === id) {
          MatgenGlobal.editor.cur().fabric.remove(o);
        }
      });
      MatgenGlobal.editor.cur().fabric.renderAll();
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting component',
        promise: UI.deleteComponent(id, _this),
      });
    });

    $(document).off('click', '.save-option');
    $(document).on('click', '.save-option', async e => {
      e.preventDefault();
      const componentId = $(e.currentTarget).attr('data-component-id');
      MatgenGlobal.UI.saveComponentOption(componentId);
    });

    $(document).off('click', '.create-option');
    $(document).on('click', '.create-option', async e => {
      e.preventDefault();
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading option create form',
        promise: MatgenGlobal.MatgenUIFunctions.createOption(
          $(e.currentTarget).attr('data-component-id')
        ),
      });
    });

    $(document).off('mouseenter', '.tree-li');
    $(document).on('mouseenter', '.tree-li', e => {
      handleSideBarSelection(e);
    });

    $(document).off('mouseleave', '.tree-li');
    $(document).on('mouseleave', '.tree-li', e => {
      $('.tree-li').removeClass('active');
      MatgenGlobal.editor.cur().hoverOutline(e, true);
      MatgenGlobal.editor.cur().fabric.discardActiveObject();
    });

    $(document).off('click keypress', '.tree-li:not(#sidebar-header)');
    $(document).on('click keypress', '.tree-li:not(#sidebar-header)', e => {
      const li = $(e.target).closest('.tree-li');
      if (
        MatgenGlobal.UI.a11yClick(e) &&
        li &&
        !li.hasClass('matgen-disabled')
      ) {
        if ($(e.target).hasClass('node-name')) {
          if (e.keyCode === 32) {
            return;
          }
        }
        e.preventDefault();
        handleSideBarSelection(e);
        if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
          if (li.attr('data-study-data')) {
            if (li.hasClass('image')) {
              MatgenGlobal.UI.userUpload();
            }
            if (li.hasClass('textbox')) {
              const objects = MatgenGlobal.editor.cur().fabric.getObjects();
              const curObj = objects.find(o => o.id === li.attr('data-id'));
              if (MatgenGlobal.microsite_vars) {
                const joinData = ['primary_contact', 'health_status'];
                let studyData =
                  MatgenGlobal.microsite_vars[li.attr('data-study-data')];
                if (joinData.includes(li.attr('data-study-data'))) {
                  studyData = studyData.join('\n');
                } else if (li.attr('data-study-data') === 'investigators') {
                  studyData = MatgenGlobal.microsite_vars[studyData][0]
                    .map(o => {
                      return `${decode(o.name).replace(
                        ' (not required)',
                        ''
                      )}: ${decode(o.value)}`;
                    })
                    .join('\n');
                } else if (li.attr('data-study-data') === 'locations') {
                  const states = Object.keys(
                    MatgenGlobal.microsite_vars[studyData]
                  );
                  const output = [];
                  for (let i = 0; i < states.length; i++) {
                    output.push(states[i]);
                    const locs =
                      MatgenGlobal.microsite_vars[studyData][states[i]];
                    for (let j = 0; j < locs.length; j++) {
                      const loc = locs[j];
                      for (let k = 0; k < loc.length; k++) {
                        const json = JSON.parse(loc[k].text);
                        output.push(`${json.label}: ${loc[k].userAnswer}`);
                      }
                      output.push('\n');
                    }
                    output.push('\n');
                  }
                  studyData = output.join('\n');
                } else if (li.attr('data-study-data') === 'faq') {
                  studyData = MatgenGlobal.microsite_vars[studyData]
                    .map(o => `${o.text}: ${o.userAnswer}`)
                    .join('\n\n');
                }
              }
              MatgenGlobal.UI.userStudyDataTextForm(
                li.attr('data-id'),
                curObj.text,
                curObj.fontSize
              );
            }
            if (li.hasClass('rich-text')) {
              const objects = MatgenGlobal.editor.cur().fabric.getObjects();
              const curObj = objects.find(o => o.id === li.attr('data-id'));
              if (
                MatgenGlobal &&
                MatgenGlobal.PassModals &&
                Array.isArray(MatgenGlobal.PassModals) &&
                MatgenGlobal.PassModals.includes('richtext')
              ) {
                MatgenGlobal.emit({
                  event: 'matgen-event-richtext-modal',
                  detail: curObj,
                });
              } else {
                SidebarHeader.richTextModal();
              }
            }
          } else {
            if (li.attr('data-component-id')) {
              MatgenGlobal.UI.showComponentOptions(
                li.attr('data-component-id')
              );
            } else {
              if (li.hasClass('image')) {
                MatgenGlobal.UI.userUpload();
              }
              if (li.hasClass('textbox')) {
                MatgenGlobal.UI.userTextForm(li.attr('data-id'));
              }
              if (li.hasClass('rich-text')) {
                const objects = MatgenGlobal.editor.cur().fabric.getObjects();
                const curObj = objects.find(o => o.id === li.attr('data-id'));
                if (
                  MatgenGlobal &&
                  MatgenGlobal.PassModals &&
                  Array.isArray(MatgenGlobal.PassModals) &&
                  MatgenGlobal.PassModals.includes('richtext')
                ) {
                  MatgenGlobal.emit({
                    event: 'matgen-event-richtext-modal',
                    detail: curObj,
                  });
                } else {
                  SidebarHeader.richTextModal();
                }
              }
            }
          }
        }
      }
    });

    $(document).off('blur', '.tree-li');
    $(document).on('blur', '.tree-li', e => {
      $('.tree-li').removeClass('active');
      MatgenGlobal.editor.cur().hoverOutline(e, true);
      MatgenGlobal.editor.cur().fabric.discardActiveObject();
    });

    $(document).off('click', '.add-option');
    $(document).on('click', '.add-option', e => {
      e.preventDefault();
      MatgenGlobal.MatgenUIFunctions.addOption($(e.target).attr('data-id'));
    });

    $(document).off('click', '.template-make-component');
    $(document).on('click', '.template-make-component', async e => {
      e.preventDefault();
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving component',
        promise: UI.saveComponent($(e.currentTarget).attr('data-id')),
      });
    });

    $(document).off('click', '#group-make-component');
    $(document).on('click', '#group-make-component', async e => {
      e.preventDefault();
      const obj = MatgenGlobal.editor.cur().fabric.getActiveObject();
      const group = UI.group(obj);
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving component',
        promise: UI.saveComponent(group.id),
      });
    });

    $(document).off('click', '.object-visible');
    $(document).on('click', '.object-visible', e => {
      e.preventDefault();
      e.stopPropagation();

      const componentId = $(e.currentTarget).attr('data-component-id');
      let visible = true;
      if (componentId) {
        MatgenGlobal.editor
          .cur()
          .fabric.getObjects()
          .forEach(o => {
            if (o.componentId === componentId) {
              o.layerVisible = !o.layerVisible;
              o.visible = o.layerVisible !== false;
              if (o.visible === false) {
                visible = false;
              }
            }
          });
        if (visible === false) {
          MatgenGlobal.editor.cur().fabric.discardActiveObject();
        }
        MatgenGlobal.editor.cur().fabric.renderAll();
        debouncedRefresh(_this, componentId, 'object-visible', false);
      } else {
        let id = $(e.currentTarget).attr('data-id');
        id = id.replace(/^gcid-/, '');
        const obj = MatgenGlobal.UI.findById(
          MatgenGlobal.editor.cur().fabric.getObjects(),
          id
        );
        if (obj) {
          const visible = obj.layerVisible === false ? false : true;
          obj.layerVisible = !visible;
          obj.visible = obj.layerVisible !== false;
          if (obj.visible === false) {
            MatgenGlobal.editor.cur().fabric.discardActiveObject();
          } else {
            MatgenGlobal.editor.cur().fabric.setActiveObject(obj);
          }
          MatgenGlobal.editor.cur().fabric.renderAll();

          debouncedRefresh(_this, null, 'object-visible', false);
        }
      }
    });

    $(document).off('click', '.component-edit');
    $(document).on('click', '.component-edit', e => {
      e.preventDefault();
      const li = $(e.target).closest('.tree-li');
      li.trigger('click');
      const id = li.attr('data-id');

      const objects = MatgenGlobal.editor.cur().fabric.getObjects();

      if (id.includes('gcid')) {
        objects.forEach(o => {
          if (o.componentId === id.replace('gcid-', '')) {
            o.allowUploads = !o.allowUploads;
          }
        });
        MatgenGlobal.editor.cur().fabric.renderAll();
        _this.markComponentDirty(id.replace('gcid-', ''));
      } else {
        const obj = MatgenGlobal.UI.findById(objects, id);

        if (obj) {
          obj.allowUploads = !obj.allowUploads;
          MatgenGlobal.editor.cur().fabric.renderAll();
          if (obj.componentId) {
            _this.markComponentDirty(obj.componentId);
          } else {
            _this.markTemplateDirty();
          }
        }
      }
    });

    $(document).off('click', '.component-uploads');
    $(document).on('click', '.component-uploads', e => {
      e.preventDefault();
      const li = $(e.target).closest('.tree-li');
      li.trigger('click');
      const curObj = UI.findById(
        MatgenGlobal.editor.cur().fabric.getObjects(),
        li.attr('data-id')
      );

      curObj.allowUploads = !curObj.allowUploads;
      MatgenGlobal.editor.cur().fabric.renderAll();
      MatgenGlobal.sidebar.refresh(
        MatgenGlobal.sidebar,
        curObj.componentId,
        'component-uploads'
      );
    });

    $(document).off('click', '.object-study-data');
    $(document).on('click', '.object-study-data', e => {
      e.preventDefault();
      const li = $(e.target).closest('.tree-li');
      li.trigger('click');
      const curObj = UI.findById(
        MatgenGlobal.editor.cur().fabric.getObjects(),
        li.attr('data-id')
      );
      MatgenGlobal.UI.studyDataForm(curObj);
    });

    $(document).off('click', '.object-accessible');
    $(document).on('click', '.object-accessible', e => {
      e.preventDefault();
      const li = $(e.target).closest('.tree-li');
      li.trigger('click');
      const curObj = UI.findById(
        MatgenGlobal.editor.cur().fabric.getObjects(),
        li.attr('data-id')
      );
      MatgenGlobal.UI.handle508(curObj);
    });

    $(document).off('click', '.object-accessible-component');
    $(document).on('click', '.object-accessible-component', e => {
      e.preventDefault();
      const li = $(e.target).closest('.tree-li');
      li.trigger('click');
      const curObj = UI.findById(
        MatgenGlobal.editor.cur().fabric.getObjects(),
        li.attr('data-id')
      );
      MatgenGlobal.UI.handle508(curObj);
    });

    $(document).off('click', '.object-accessible-group-component');
    $(document).on('click', '.object-accessible-group-component', e => {
      e.preventDefault();
      const li = $(e.target).closest('.tree-li');
      li.trigger('click');
      const objects = MatgenGlobal.editor.cur().fabric.getObjects();
      const component = objects
        .filter(o => o.componentId === li.attr('data-component-id'))
        .map(o => {
          return {
            readOrder: o.readOrder,
            componentReadOrder: o.componentReadOrder,
          };
        });
      MatgenGlobal.UI.handle508({
        type: 'group',
        id: li.attr('data-id'),
        componentId: li.attr('data-component-id'),
        readOrder: component[0].componentReadOrder,
      });
    });

    $(document).off('click', '.object-locked');
    $(document).on('click', '.object-locked', e => {
      e.preventDefault();

      const componentId = $(e.currentTarget).attr('data-component-id');
      if (componentId) {
        MatgenGlobal.editor
          .cur()
          .fabric.getObjects()
          .forEach(o => {
            if (o.componentId === componentId) {
              o.layerLocked = !o.layerLocked;
              o.selectable = o.layerLocked !== true;
              o.evented = o.layerLocked !== true;
            }
          });
        MatgenGlobal.editor.cur().fabric.requestRenderAll();
        debouncedRefresh(_this, componentId, 'object-locked', false);
      } else {
        let id = $(e.currentTarget).attr('data-id');
        id = id.replace(/^gcid-/, '');
        const obj = MatgenGlobal.UI.findById(
          MatgenGlobal.editor.cur().fabric.getObjects(),
          id
        );
        if (obj) {
          obj.layerLocked = !obj.layerLocked;
          const selectable = obj.layerLocked === true ? false : true;
          obj.layerLocked = !selectable;
          obj.selectable = obj.layerLocked !== true;
          if (obj.selectable === true) {
            MatgenGlobal.editor.cur().fabric.setActiveObject(obj);
          } else {
            window.setTimeout(() => {
              MatgenGlobal.editor.cur().fabric.discardActiveObject();
            }, 250);
          }
          MatgenGlobal.editor.cur().fabric.renderAll();
        }
      }
    });

    $(document).off('click', '.delete-object');
    $(document).on('click', '.delete-object', e => {
      e.preventDefault();
      const componentId = $(e.currentTarget).attr('data-component-id');
      if (componentId && componentId !== 'undefined') {
        const objects = MatgenGlobal.editor
          .cur()
          .fabric.getObjects()
          .filter(o => o.componentId === componentId);
        if (objects && objects[0] && objects[0].type !== 'group') {
          const obj = MatgenGlobal.UI.findById(
            MatgenGlobal.editor.cur().fabric.getObjects(),
            $(e.target).attr('data-id')
          );
          MatgenGlobal.editor.cur().fabric.remove(obj);

          MatgenGlobal.editor.cur().fabric.discardActiveObject();
          const sel = new fabric.ActiveSelection(
            MatgenGlobal.editor
              .cur()
              .fabric.getObjects()
              .filter(o => o.componentId === componentId),
            {
              canvas: MatgenGlobal.editor.cur().fabric,
            }
          );
          MatgenGlobal.editor.cur().fabric.setActiveObject(sel);

          let group = MatgenGlobal.editor
            .cur()
            .fabric.getActiveObject()
            .toGroup();

          group.id = UUID();
          group.componentId = componentId;
          group = Object.assign(
            group,
            MatgenGlobal.editor.cur().FabricObjectDefaults
          );
          group.getObjects().forEach(o => {
            o.groupId = group.id;
          });

          MatgenGlobal.editor.cur().fabric.renderAll();

          debouncedRefresh(_this, componentId, 'delete-object');
        } else {
          const fabric = MatgenGlobal.editor.cur().fabricJS;
          const group = new fabric.Group([]);

          group.componentId = componentId;
          group.id = UUID();

          objects.forEach(obj => {
            MatgenGlobal.editor.cur().fabric.remove(obj);
          });

          MatgenGlobal.editor.cur().fabric.add(group);
          group.setCoords();
          MatgenGlobal.editor.cur().fabric.renderAll();

          debouncedRefresh(_this, componentId, 'delete-object');
        }
      } else {
        const obj = MatgenGlobal.UI.findById(
          MatgenGlobal.editor.cur().fabric.getObjects(),
          $(e.currentTarget).attr('data-id')
        );
        MatgenGlobal.editor.cur().fabric.remove(obj);
        MatgenGlobal.editor.cur().fabric.renderAll();
        debouncedRefresh(_this, null, 'delete-object');
      }
    });

    $(document).off('click', '.template-page-edit');
    $(document).on('click', '.template-page-edit', e => {
      e.preventDefault();
      if (!MatgenGlobal.SuppressTooltips) {
        $('[data-bs-toggle="tooltip"]').tooltip('hide');
        MatgenGlobal.hideTooltips();
        $('[data-bs-toggle="dropdown"]').dropdown('hide');
      } else {
        MatgenGlobal.hideTooltips();
        $('.m4c-matgen .dropdown-menu').hide();
      }
      if (MatgenGlobal.templateDirty) {
        UI.confirm(
          'Unsaved Changes!',
          '<p style="max-width:320px;">There are unsaved changes on the current page, navigating away will discard them. Are you sure you wish to continue?</p>',
          'Continue',
          'Cancel',
          () => _this.loadPage(e)
        );
      } else {
        _this.loadPage(e);
      }
    });

    $(document).off('click', '#template-add-page');
    $(document).on('click', '#template-add-page', async e => {
      e.preventDefault();
      if (!MatgenGlobal.SuppressTooltips) {
        $('[data-bs-toggle="tooltip"]').tooltip('hide');
        MatgenGlobal.hideTooltips();
        $('[data-bs-toggle="dropdown"]').dropdown('hide');
      } else {
        MatgenGlobal.hideTooltips();
        $('.m4c-matgen .dropdown-menu').hide();
      }
      if (MatgenGlobal.templateDirty) {
        UI.confirm(
          'Unsaved Changes!',
          '<p style="max-width:320px;">There are unsaved changes on the current page, navigating away will discard them. Are you sure you wish to continue?</p>',
          'Continue',
          'Cancel',
          _this.addPage
        );
      } else {
        _this.addPage();
      }
    });

    $(document).off('click', '#template-add-image');
    $(document).on('click', '#template-add-image', e => {
      e.preventDefault();
      UI.upload(() => {
        $('#template-add-image')
          .blur()
          .removeClass('active');
        if (document.getElementById('file-input').files[0]) {
          MatgenGlobal.editor
            .cur()
            .addImg(
              document.getElementById('file-input').files[0],
              MatgenGlobal.editor.cur().fabric.getActiveObject()
            );
        }
        document
          .querySelector('body')
          .removeChild(document.getElementById('file-input'));
      });
    });

    $(document).off('click', '.component-add-image');
    $(document).on('click', '.component-add-image', e => {
      e.preventDefault();
      const componentId = $(e.currentTarget).attr('data-component-id');
      UI.upload(() => {
        $('.component-add-image')
          .blur()
          .removeClass('active');
        if (document.getElementById('file-input').files[0]) {
          MatgenGlobal.editor
            .cur()
            .addImg(
              document.getElementById('file-input').files[0],
              componentId
            );
        }
        $('#file-input').remove();
      });
    });

    $(document).off('click', '#template-help');
    $(document).on('click', '#template-help', e => {
      e.preventDefault();
      UI.showHelp();
    });

    $(document).off('click', '#template-tag');
    $(document).on('click', '#template-tag', e => {
      e.preventDefault();
      MatgenGlobal.hideTooltips();
      MatgenGlobal.Router.goTo(`tags/${MatgenGlobal.editor.templateId}`);
    });

    $(document).off('click', '#template-add-text');
    $(document).on('click', '#template-add-text', e => {
      e.preventDefault();
      UI.textForm();
    });

    $(document).off('click', '.component-add-text');
    $(document).on('click', '.component-add-text', e => {
      e.preventDefault();
      const componentId = $(e.currentTarget).attr('data-component-id');
      UI.textForm({ componentId });
    });

    $(document).off('blur', '.node-name');
    $(document).on('blur', '.node-name', e => {
      _this.nameEdit(e, _this);
    });

    $(document).off('keypress', '.node-name');
    $(document).on('keypress', '.node-name', e => {
      if (e.keyCode === 13) {
        e.preventDefault();
        _this.nameEdit(e, _this);
      }
    });

    $(document).off('click', '#template-save');
    $(document).on('click', '#template-save', async e => {
      if ($('.component-save.text-danger').length > 0) {
        MatgenGlobal.UI.handleError(
          'Unsaved component changes',
          'There are unsaved component changes. Save them, or refresh the page to discard them.'
        );
        return false;
      }
      MatgenGlobal.supressSideBarStopLoading = true;
      const id = $(e.target)
        .closest('#template-save')
        .attr('data-id');
      const template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(id),
      });
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Deleting old study data connections',
          promise: MatgenGlobal.Data.deletePageStudyData(
            template.id,
            MatgenGlobal.editor.curPageId
          ),
        });
      } catch (e) {
        console.error(e);
      }
      const pageObj = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template page object',
        promise: UI.getPageObject(),
      });
      for (const obj of pageObj.objects) {
        if (obj.studyDataConnection) {
          //console.error('STUDY DATA:', obj.studyDataConnection);
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving new study data connections',
            promise: MatgenGlobal.Data.saveTemplateStudyData(
              {
                template_id: template.id,
                question_id: obj.studyDataConnection,
                page_id: MatgenGlobal.editor.curPageId,
              },
              false
            ),
          });
        }
      }
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving template page JSON',
        promise: MatgenGlobal.Data.savePageFile(
          MatgenGlobal.editor.curPageId,
          pageObj,
          template.tenant_id
        ),
      });

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving template page preview',
        promise: UI.savePagePreview(false, template.tenant_id),
      });

      window.setTimeout(() => {
        _this.markTemplateClean();
      }, 350);
    });

    $(document).off('click', '#material-save');
    $(document).on('click', '#material-save', async () => {
      const id = window.location.href.substring(
        window.location.href.lastIndexOf('/') + 1
      );
      if (!id) {
        UI.handleError('Missing material ID', 'Material ID is required.');
        return false;
      }
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving page',
        promise: UI.savePage(id),
      });
      window.setTimeout(() => {
        _this.markTemplateClean();
      }, 350);
    });

    $(document).off('click', '#material-download');
    $(document).on('click', '#material-download', async e => {
      MatgenUIFunctions.downloadMaterial($(e.currentTarget).attr('data-id'));
    });

    $(document).off('click', '#template-download');
    $(document).on('click', '#template-download', async e => {
      e.preventDefault();
      MatgenUIFunctions.downloadTemplate($(e.currentTarget).attr('data-id'));
    });

    $(document).off('click', '#template-pdf-render');
    $(document).on('click', '#template-pdf-render', async e => {
      MatgenUIFunctions.downloadTemplate(
        $(e.currentTarget).attr('data-id'),
        true,
        true
      );
    });

    $(document).off('click', '#template-pdf-render-508');
    $(document).on('click', '#template-pdf-render-508', async e => {
      MatgenUIFunctions.downloadTemplate(
        $(e.currentTarget).attr('data-id'),
        true,
        true,
        true
      );
    });

    $(document).off('click', '#zoom-default');
    $(document).on('click', '#zoom-default', async e => {
      e.preventDefault();
      MatgenGlobal.UI.zoomEditor();
    });

    $(document).off('click', '#zoom-50');
    $(document).on('click', '#zoom-50', async e => {
      e.preventDefault();
      MatgenGlobal.UI.zoomEditor({
        width: '50%',
      });
    });

    $(document).off('click', '#zoom-fit');
    $(document).on('click', '#zoom-fit', async e => {
      e.preventDefault();
      const maxHeight = $(
        `#${MatgenGlobal.editor.cur().canvasContainerId}-scaler`
      )
        .parent()
        .height();
      const maxWidth = $(
        `#${MatgenGlobal.editor.cur().canvasContainerId}-scaler`
      )
        .parent()
        .width();
      const curWidth = $(
        `#${MatgenGlobal.editor.cur().canvasContainerId}`
      ).width();
      const curHeight = $(
        `#${MatgenGlobal.editor.cur().canvasContainerId}`
      ).height();

      const widthRatio = maxWidth / curWidth;
      const heightRatio = maxHeight / curHeight;
      const bestRatio = Math.min(widthRatio, heightRatio);
      const width = curWidth * bestRatio;
      const height = curHeight * bestRatio;
      MatgenGlobal.UI.zoomEditor({ width, height });
    });

    $(document).off('click', '.create-rich-text, .edit-rich-text');
    $(document).on('click', '.create-rich-text, .edit-rich-text', async e => {
      e.preventDefault();
      e.stopPropagation();
      const creating = $(e.target).hasClass('create-rich-text');
      SidebarHeader.richTextModal(creating);
    });

    $(document).off('click', '.component-edit-group');
    $(document).on('click', '.component-edit-group', async e => {
      e.preventDefault();
      e.stopPropagation();
    });

    $(document).off('show.bs.dropdown', '#sidebar');
    $(document).on('show.bs.dropdown', '#sidebar', e => {
      const id = `#${$(e.relatedTarget).attr('id')}-dropdown`;
      let target = 'body';
      if (MatgenGlobal.PageLoaderTarget) {
        target = MatgenGlobal.PageLoaderTarget;
      }
      $(id)
        .detach()
        .appendTo(target)
        .show();
    });

    $(document).off('hide.bs.dropdown', '#sidebar');
    $(document).on('hide.bs.dropdown', '#sidebar', e => {
      const id = `#${$(e.relatedTarget).attr('id')}-dropdown`;
      $(id)
        .detach()
        .appendTo(e.target)
        .hide();
    });

    $(document).off('click', '.mark-rich-text-uneditable');
    $(document).on('click', '.mark-rich-text-uneditable', e => {
      e.preventDefault();
      e.stopPropagation();
      const curObj = UI.findById(
        MatgenGlobal.editor.cur().fabric.getObjects(),
        $(e.target).attr('data-id')
      );
      if (curObj.uneditable !== true) {
        curObj.uneditable = true;
      } else {
        delete curObj.uneditable;
      }
      MatgenGlobal.editor.cur().fabric.renderAll();
      MatgenGlobal.sidebar.refresh(
        MatgenGlobal.sidebar,
        curObj.componentId,
        'mark-rich-text-uneditable',
        false
      );
      MatgenGlobal.sidebar.markTemplateDirty();
    });

    MatgenGlobal.editor.cur().fabric.off('object:added');
    MatgenGlobal.editor.cur().fabric.on('object:added', e => {
      if (e.target.sidebarIgnore || MatgenGlobal.IgnoreAdd) {
        return false;
      }
      const componentId = e.target.componentId ? e.target.componentId : false;
      debouncedRefresh(_this, componentId, 'object:added', false);
    });

    MatgenGlobal.editor.cur().fabric.off('object:removed');
    MatgenGlobal.editor.cur().fabric.on('object:removed', e => {
      if (e.target.sidebarIgnore || MatgenGlobal.IgnoreAdd) {
        return false;
      }
      let openItems = sessionStorage.getItem('matgen-tree-state');
      if (!openItems) {
        openItems = [];
      } else {
        openItems = JSON.parse(openItems);
      }

      const index = openItems.indexOf(e.target.id);
      if (index !== -1) {
        openItems.splice(index, 1);
      }
      sessionStorage.setItem('matgen-tree-state', JSON.stringify(openItems));
      const componentId = e.target.componentId ? e.target.componentId : false;
      debouncedRefresh(_this, componentId, 'object:removed');
    });

    MatgenGlobal.editor.cur().fabric.off('object:modified');
    MatgenGlobal.editor.cur().fabric.on('object:modified', e => {
      if (e.target.sidebarIgnore || MatgenGlobal.IgnoreAdd) {
        return false;
      }
      let componentId = e.target.componentId ? e.target.componentId : false;
      if (!componentId && e.target.type === 'activeSelection') {
        const comp = e.target.getObjects().find(o => o.componentId);
        if (comp) {
          componentId = comp.componentId;
        }
      }
      debouncedRefresh(_this, componentId, 'object:modified');
    });

    document.addEventListener('canvas-objects-changed', e => {
      const componentId = e.detail.componentId ? e.detail.componentId : false;
      debouncedRefresh(_this, componentId, 'canvas-objects-changed');
    });
  });
};

export { init };
